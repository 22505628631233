import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectUser } from './slices/authSlice';

const PrivateRoute: React.FC<RouteProps & { isAdminRoute?: boolean }> = ({
    children,
    isAdminRoute,
    ...rest
}) => {
    const user = useSelector(selectUser);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname:
                                user.role === 'admin' || user.role === 'coordinator'
                                    ? '/admin'
                                    : '/survey',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};
export default PrivateRoute;
