import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const HeaderContainer = styled.div<{ percentage: number; firstQuestion: boolean }>`
    display: grid;
    grid-template-columns: 10% auto 14% 5%;
    place-items: center;
    height: 100%;
    width: 100%;
    font-family: NunitoBold;
    font-size: 24px;
    position: sticky;
    top: 0;
    background-color: ${colours.white};
    z-index: 10;

    @media ${sizes.XP} {
        grid-template-columns: ${(props) => (props.firstQuestion ? `auto` : `20% auto 14% 5%`)};
        font-size: 20px;
    }

    @media ${sizes.G} {
        grid-template-columns: 10% auto 14% 3%;
    }

    ::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: solid 4px ${colours.lightGrey};
        width: 100%;
    }

    ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: solid 4px ${colours.pink};
        width: ${(props) => props.percentage * 100}%;
        z-index: 0;
    }
`;

export const PreviousButton = styled.button`
    border: none;

    @media ${sizes.G} {
        display: none;
    }
`;

export const HeaderLogo = styled.img`
    cursor: pointer;

    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.G} {
        display: revert;
        height: 50px;
        margin-left: 15px;
    }
`;

export const TitleDesktop = styled.div`
    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.G} {
        display: revert;
    }
`;

export const TitleMobile = styled.div`
    @media ${sizes.G} {
        display: none;
    }
`;

export const LogoutButton = styled.div`
    color: ${colours.purple};
    font-size: 16px;
    cursor: pointer;

    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.G} {
        display: revert;
    }
`;

export const Spacer = styled.div``;

export const MobileLogo = styled.img`
    @media ${sizes.XP} {
        display: revert;
    }

    @media ${sizes.M} {
        display: none;
    }
`;
