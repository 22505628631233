import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

export const SurveyCenterContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media ${sizes.G} {
        width: 70%;
    }
`;

export const TitleContainer = styled.div`
    @media ${sizes.XP} {
        margin-top: 40px;
    }

    @media ${sizes.G} {
        margin-top: 92px;
    }
`;

export const QuestionCounter = styled.div`
    font-family: PoppinsRegular;
    font-size: 18px;
`;

export const QuestionTitle = styled.div`
    font-family: NunitoBold;
    color: ${colours.purple};

    @media ${sizes.XP} {
        font-size: 26px;
        margin: 16px;
    }

    @media ${sizes.G} {
        font-size: 30px;
        margin-top: 13px;
    }
`;

export const AnswersContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
`;
