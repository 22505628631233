import styled from 'styled-components';

import Active from '../assets/icons/status_active.svg';
import Dropout from '../assets/icons/status_dropout.svg';

import { colours } from '../constants/colours';
import { sizes } from '../constants/mediaSizes';

export const DropDownBorder = `1px solid ${colours.mediumGrey}`;

export const boxShadowUp = `-3px -4px 18px ${colours.dropShadow}`;

export interface IIconComponent {
    hideIcon?: boolean;
}

export interface IStatus extends IIconComponent {
    status?: number;
}

export interface ButtonStyleProps {
    active: boolean;
    justifySelf?: string;
}

export const RadioGroup = styled.div`
    display: flex;
    flex-direction: row;
    width: 332px;
`;

export const FieldError = styled.div`
    font-family: PoppinsRegular;
    color: ${colours.errorRed};
`;

export const StyledButton = styled.button<
    React.HTMLAttributes<HTMLButtonElement> & ButtonStyleProps
>`
    background-color: ${(props) => (props.active ? colours.purple : colours.mediumGrey)};
    justify-self: ${(props) => (props.justifySelf ? props.justifySelf : 'start')};
    color: ${colours.white};
    font-family: NunitoBold;
    border-radius: 10px;
    border: none;

    :hover {
        background-color: ${colours.purpleHover};
    }

    @media ${sizes.XP} {
        min-width: 167px;
        height: 54px;
        font-size: 20px;
        margin: 30px;
    }

    @media ${sizes.G} {
        width: 213px;
        height: 64px;
        padding: 0;
    }
`;

export const PageContainer = styled.div`
    display: grid;
    height: 100vh;
    place-items: center;

    @media ${sizes.XP} {
        grid-template-rows: 120px auto 130px;
    }

    @media ${sizes.G} {
        grid-template-rows: 77px auto 77px;
    }
`;

export const LanguageSwitchContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
`;

export const LanguageSwitch = styled.button`
    height: 100%;
    right: 0;
    width: 50px;
    background-color: ${colours.purple};
    color: white;
    border: none;
    cursor: pointer;

    :hover {
        background-color: ${colours.purpleHover};
    }

    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.G} {
        display: revert;
    }
`;

export const Logo = styled.img`
    justify-self: center;
    @media ${sizes.XP} {
        width: 179px;
    }

    @media ${sizes.G} {
        width: 262px;
    }
`;

export const Title = styled.div`
    font-family: NunitoBold;
    color: ${colours.purple};
    text-align: center;

    @media ${sizes.XP} {
        font-size: 24px;
        padding-top: 15px;
    }

    @media ${sizes.M} {
        font-size: 32px;
    }
`;

export const UserStatus = styled.div<IStatus>`
    flex: 0 0 6%;
    height: ${(props) => (!props.hideIcon ? `10px` : `auto`)};

    background: ${(props) =>
        !props.hideIcon
            ? `transparent url(${props.status === 1 ? Active : Dropout}) no-repeat 10px center`
            : ''};
`;
