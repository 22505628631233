import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const CenterContainer = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
    width: 80%;
`;

export const Text = styled.div`
    font-family: NunitoBold;
    text-align: center;

    @media ${sizes.XP} {
        font-size: 20px;
        margin: 15px 0;
    }

    @media ${sizes.M} {
        font-size: 28px;
    }
`;

export const Note = styled.div`
    text-align: center;

    @media ${sizes.XP} {
        font-size: 16px;
        margin: 10px 0;
    }

    @media ${sizes.M} {
        font-size: 22px;
    }
`;

export const Link = styled.a`
    color: ${colours.purple};
    text-decoration: none;
`;
