import styled from 'styled-components';

import { colours } from '../../constants/colours';
import Bin from '../../assets/icons/bin.svg';
import { IIconComponent } from '../../styles/common';

interface IAction {
    color?: string;
    pointer?: boolean;
}

export const UsersContainer = styled.div`
    padding-top: 20px;
    max-width: 1335px;
    width: 90%;
    margin: 0 auto;
    font-family: PoppinsRegular;
    font-size: 14px;
`;

export const Row = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    height: 61px;
    border-bottom: solid 1px ${colours.grey};
`;

export const Header = styled.div`
    cursor: pointer;
    display: flex;
    height: 40px;
    align-items: center;
    font-family: PoppinsMedium;
    flex-flow: row wrap;
    background-color: ${colours.lightGrey};
    justify-content: center;
`;

export const Item = styled.div`
    flex: 0 0 15%;
    padding: 10px;
    box-sizing: border-box;
`;

export const UserId = styled.div`
    width: 6%;
    flex: 0 0 6%;
`;

export const FirstName = styled.div`
    width: 13%;
    flex: 0 0 13%;
`;

export const LastName = styled.div`
    width: 25%;
    flex: 0 0 25%;
`;

export const Email = styled.div`
    width: 35%;
    flex: 0 0 35%;
`;

export const Action = styled.div<IAction>`
    cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
    width: 7%;
    flex: 0 0 7%;
    color: ${(props) => (props.color ? props.color : colours.purple)};
`;

export const BinItem = styled.div<IIconComponent>`
    with: 18px;
    height: 18px;
    flex: 0 0 18px;
    cursor: ${(props) => (!props.hideIcon ? 'pointer' : 'default')};
    pointer-events: ${(props) => (!props.hideIcon ? 'default' : 'none')};
    background-image: url(${(props) => (!props.hideIcon ? Bin : '')});
`;
