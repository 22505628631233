/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import * as _ from 'lodash';

import { Question } from '../constants/questionTypes';

import { BACKEND_ROUTES } from '../constants/routes';
import { HTTPGetRequest } from '../utils/request';

const lastQuestionJSON = localStorage.getItem('last_question');
let lastQuestion;

if (lastQuestionJSON) {
    const lastQuestionObject = JSON.parse(lastQuestionJSON);
    if (lastQuestionObject.question_id) {
        lastQuestion = lastQuestionObject.question_id;
    }
}
export interface QuestionChildType {
    id: number;
    text: string;
    customAnswer: boolean;
    questionId: number | null;
    questionTypeId: number | null;
    questionOptionParentId: number | null;
    selected: any;
}
export interface QuestionOptionType {
    id: number;
    text: string;
    customAnswer: boolean;
    answer: any;
    questionId: number | null;
    questionTypeId: number | null;
    questionOptionParentId: number | null;
    question_child: QuestionChildType[];
    min?: number;
    max?: number;
    selected: any;
}
export interface QuestionType {
    id: number;
    text: string;
    order: number;
    sectionId?: number;
    depends_on_question: QuestionType;
    section: SectionType;
    questionTypeId?: number;
    conditional_question?: QuestionType;
    answer: any;
    totalQuestions: number;
    totalSectionQuestions: number;
    min: number | null;
    max: number | null;
    subtype: string | null;
    question_option: QuestionOptionType[];
    question_type: {
        id: number;
        type: string;
    };
}
export interface SectionType {
    id: number;
    name: string;
    type: string;
    order: number;
    surveyId: number;
    question: QuestionType[];
}
export interface SurveyType {
    survey: SectionType[];
}
export interface ISurveyState {
    survey?: QuestionType;
    error?: string;
    loading: boolean;
    saveObject: any;
    currentQuestion: number;
}
export const initialState: ISurveyState = {
    loading: false,
    saveObject: [],
    currentQuestion: lastQuestion || 1,
};

export const getQuestion = createAsyncThunk<QuestionType, any, { rejectValue: string }>(
    'get/survey',
    async (question, { rejectWithValue }) => {
        try {
            localStorage.setItem(
                'last_question',
                JSON.stringify({
                    question_id: question,
                }),
            );

            const response = await axios.get<{ data: QuestionType }>(
                `${BACKEND_ROUTES.SURVEY_DB}/${question}/1`,
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                },
            );

            return response.data.data;
        } catch (e) {
            if (e.response?.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('user_id');
            }

            // todo add a route to login here
            const error = e as AxiosError<string>;
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    },
);

export const saveQuestion = createAsyncThunk<any, boolean, { rejectValue: string }>(
    'save/survey',
    async (increment, { rejectWithValue, getState, dispatch }) => {
        let token = null;

        if (localStorage.getItem('token')) {
            token = localStorage.getItem('token');
        }

        const state: any = getState();
        let objToReturn;
        if (!_.isEmpty(state.survey.saveObject)) {
            try {
                const response = await axios.post(
                    `${BACKEND_ROUTES.ANSWER}`,
                    state.survey.saveObject,
                    {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    },
                );

                objToReturn = response.data.data;
            } catch (e) {
                const error = e as AxiosError<string>;
                if (error.response?.data) {
                    return rejectWithValue(error.response.data);
                }
                return rejectWithValue(error.message);
            }
        }
        let currentQuestion = state.survey.currentQuestion - 1;

        if (increment) {
            currentQuestion = Number(state.survey.currentQuestion) + 1;
        } else {
            const questionResponse = await HTTPGetRequest<QuestionType>(
                `${BACKEND_ROUTES.PREVIOUS_QUESTION}/${state.survey.currentQuestion}/1`,
            );
            currentQuestion = questionResponse.data.data.id;
        }

        const { question_option } = state.survey.survey;
        const answered_question = question_option.filter(
            (elm: any) => elm.id === (state.survey.saveObject[0] ?? []).answer_offered_id,
        );

        // Special cases
        // If the answers has a jumpTo property
        if (answered_question.length > 0 && answered_question[0].jumpTo && increment) {
            currentQuestion = answered_question[0].jumpTo;
        }

        // If its question 19, we need to check answer on question 18.
        if (currentQuestion === 19) {
            // Fetches question 18
            const questionResponse = await axios.get<{ data: QuestionType }>(
                `${BACKEND_ROUTES.SURVEY_DB}/18/1`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                },
            );
            const { question_option } = questionResponse.data.data;
            const childs = question_option.map(
                (option) => option.question_child.filter((child) => child.selected.length > 0)[0],
            );

            // Checks if there is any answer different than 'no' in question 18
            const symptonsAnswersIndex = childs.findIndex((option) => option.text !== 'no');

            // If there is no answer different than 'no', than jump question 19.
            if (symptonsAnswersIndex === -1) {
                if (increment) {
                    currentQuestion = Number(currentQuestion) + 1;
                } else {
                    currentQuestion = Number(currentQuestion) - 1;
                }
            }
        }

        // If its question 46, we need to check answer on question 44.
        if (currentQuestion === 46) {
            const questionResponse = await axios.get<{ data: QuestionType }>(
                `${BACKEND_ROUTES.SURVEY_DB}/${currentQuestion}/1`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                },
            );
            const { depends_on_question } = questionResponse.data.data;
            const { question_option } = depends_on_question;
            const selectedAnswers = question_option.filter((option) => option.selected.length > 0);

            if (
                selectedAnswers.length > 0 &&
                selectedAnswers[0].text !== 'moreThanTwoDoses' &&
                selectedAnswers[0].text !== 'twoDoses'
            ) {
                if (increment) {
                    currentQuestion = Number(currentQuestion) + 1;
                } else {
                    currentQuestion = Number(currentQuestion) - 1;
                }
            }
        }

        // If its question 29, we need to check answer on question 26 and 25.
        if (currentQuestion === 29) {
            // Fetches question 26
            const questionResponse = await axios.get<{ data: QuestionType }>(
                `${BACKEND_ROUTES.SURVEY_DB}/26/1`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                },
            );
            const { question_option } = questionResponse.data.data;
            const selectedAnswers = question_option.filter((option) => option.selected.length > 0);

            // Fetches question 25
            const questionResponse25 = await axios.get<{ data: QuestionType }>(
                `${BACKEND_ROUTES.SURVEY_DB}/25/1`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                },
            );

            const question25Option = questionResponse25.data.data.question_option;
            const resultAnswer =
                question25Option.filter((option) => option.text === 'result')[0] ?? {};
            const { question_child } = resultAnswer;
            const positiveAnswers =
                question_child.filter((option) => option.text === 'positive')[0] ?? [];
            const selectedPositiveAnswers = positiveAnswers.selected;

            // If there is NONE positive answer inside question 25
            // Or the answer in question 26 is not yes
            // We need to jump this question.
            if (
                selectedPositiveAnswers.length === 0 &&
                selectedAnswers.length > 0 &&
                selectedAnswers[0].text !== 'yes'
            ) {
                if (increment) {
                    currentQuestion = Number(currentQuestion) + 1;
                } else {
                    currentQuestion = Number(currentQuestion) - 1;
                }
            }
        }

        // If its question 50 or 51, we need to check answer on question 49.
        if (currentQuestion === 50 || currentQuestion === 51) {
            // Fetches question 49
            const questionResponse = await axios.get<{ data: QuestionType }>(
                `${BACKEND_ROUTES.SURVEY_DB}/49/1`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                },
            );
            const { question_option } = questionResponse.data.data;
            const selectedAnswers = question_option.filter((option) => option.selected.length > 0);

            // Question 50 is asking the reasons to get a vaccine
            // So we need to jump it if he replied negatively to getting a vaccine.
            if (
                currentQuestion === 50 &&
                selectedAnswers.length > 0 &&
                (selectedAnswers[0].text === 'somewhatUnlikely' ||
                    selectedAnswers[0].text === 'veryUnlikely')
            ) {
                if (increment) {
                    currentQuestion = Number(currentQuestion) + 1;
                } else {
                    currentQuestion = Number(currentQuestion) - 1;
                }
            }

            // Question 51 is asking the reasons to DON'T get a vaccine
            // So we need to jump it if he replied positively to getting a vaccine.
            if (
                currentQuestion === 51 &&
                selectedAnswers.length > 0 &&
                (selectedAnswers[0].text === 'veryLikely' ||
                    selectedAnswers[0].text === 'somewhatLikely')
            ) {
                if (increment) {
                    currentQuestion = Number(currentQuestion) + 1;
                } else {
                    currentQuestion = Number(currentQuestion) - 1;
                }
            }
        }

        dispatch(setCurrentQuestion(currentQuestion));
        dispatch(saveObject([]));

        return objToReturn;
    },
);

export const slice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        saveObject(state, action: PayloadAction<any>) {
            const { payload } = action;

            if (_.isEmpty(payload)) {
                state.saveObject = [];
            }

            // if its an array and checkbox, its coming from a customAnswer
            if (Array.isArray(payload) && payload.length > 0 && payload[0].type === 'checkbox') {
                // get all the old elements from the checkbox and the other question types
                const newOrderElements = state.saveObject.filter(
                    (elm: any) =>
                        (elm.order !== payload[0].order && elm.type === 'checkbox') ||
                        elm.type !== 'checkbox',
                );

                // and push they new values back
                for (const element of payload) {
                    newOrderElements.push(element);
                    state.saveObject = [...newOrderElements];
                }
            } else if (!payload.order && payload.type === Question.checkbox) {
                const index = state.saveObject.findIndex((elm: any) => {
                    return (
                        elm.question_id === payload.question_id &&
                        elm.type === payload.type &&
                        elm.answer_offered_id === payload.answer_offered_id
                    );
                });
                if (index === -1) {
                    state.saveObject = [...state.saveObject, payload];
                } else {
                    state.saveObject = state.saveObject.filter(
                        (elm: any, i: number) => i !== index,
                    );
                }
            } else if (
                (typeof payload.order !== 'undefined' || payload.type === Question.checkbox) &&
                payload.answer_offered_id &&
                payload.question_id
            ) {
                const index = state.saveObject.findIndex((elm: any) => {
                    if (payload.type === Question.radio) {
                        return (
                            elm.question_id === payload.question_id &&
                            elm.order === payload.order &&
                            elm.type === payload.type
                        );
                    }
                    return (
                        elm.question_id === payload.question_id &&
                        elm.order === payload.order &&
                        elm.type === payload.type &&
                        elm.answer_offered_id === payload.answer_offered_id
                    );
                });

                if (index === -1) {
                    state.saveObject = [...state.saveObject, payload];
                } else {
                    if (payload.type === Question.checkbox) {
                        state.saveObject = state.saveObject.filter(
                            (elm: any, i: number) => i !== index,
                        );
                    } else {
                        state.saveObject[index] = {
                            ...state.saveObject[index],
                            answer: payload.answer,
                            answer_offered_id: payload.answer_offered_id,
                        };
                    }
                }
            } else {
                state.saveObject = Array.isArray(payload) ? payload : [payload];
            }
        },
        setCurrentQuestion(state, action: PayloadAction<any>) {
            const { payload } = action;
            state.currentQuestion = payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getQuestion.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(getQuestion.fulfilled, (state, { payload }) => {
            state.survey = { ...payload };
            state.loading = false;
        });
        builder.addCase(getQuestion.rejected, (state, { payload }) => {
            if (payload) {
                state.error = payload;
                state.loading = false;
            }
        });
    },
});

export const { saveObject, setCurrentQuestion } = slice.actions;

export default slice.reducer;
