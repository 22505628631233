import React from 'react';
import { useTranslation } from 'react-i18next';

import EncoreLogoFR from '../../assets/icons/encore_logo_fr.svg';
import EncoreLogoEN from '../../assets/icons/encore_logo_en.svg';

import { SchoolAndPeople } from '../../components/SchoolAndPeople';

import {
    Container,
    CenterContainer,
    RightSide,
    ContactUs,
    LogoContainer,
    LanguageSwitchMobileContainer,
    LanguageSwitchMobile,
    Login,
    LeftSide,
} from './styles';
import { Logo, LanguageSwitch, LanguageSwitchContainer } from '../../styles/common';

type Props = {
    footerText?: string;
    showAdmin?: boolean;
};

const HomeLayout: React.FC<Props> = ({ children, footerText, showAdmin }) => {
    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language

    const changeLanguage = async (lng: string) => {
        await i18n.changeLanguage(lng);
    };

    // Changes logo according to language
    const encoreLogo = language === 'en' ? EncoreLogoEN : EncoreLogoFR;

    return (
        <Container>
            <LeftSide>
                <SchoolAndPeople />
            </LeftSide>
            <RightSide>
                <LanguageSwitchContainer>
                    {showAdmin ? (
                        <Login to="/admin-login" exact>
                            {t('homepage.admin')}
                        </Login>
                    ) : null}
                    <LanguageSwitch
                        type="button"
                        onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')}
                    >
                        {language === 'en' ? 'Fr' : 'En'}
                    </LanguageSwitch>
                    <LanguageSwitchMobileContainer>
                        <LanguageSwitchMobile
                            type="button"
                            onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')}
                        >
                            {language === 'en' ? 'Fr' : 'En'}
                        </LanguageSwitchMobile>
                    </LanguageSwitchMobileContainer>
                </LanguageSwitchContainer>
                <LogoContainer>
                    <Logo src={encoreLogo} alt={t('homepage.logoAlt')} />
                </LogoContainer>
                <CenterContainer>
                    {children}
                    {footerText ? <ContactUs>{footerText}</ContactUs> : null}
                </CenterContainer>
            </RightSide>
        </Container>
    );
};

export default HomeLayout;
