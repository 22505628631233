import styled from 'styled-components';

import { sizes } from '../../constants/mediaSizes';

export const MultitypeContainer = styled.div`
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 50px;

    @media ${sizes.XP} {
        margin: 70px 20px 0 20px;
    }

    @media ${sizes.M} {
        margin-top: 70px;
    }
`;

export const OrderTitle = styled.div`
    font-family: PoppinsMedium;
    font-size: 20px;
`;

export const QuestionnaireContainer = styled.div`
    display: grid;
    grid-gap: 40px;
`;

export const QuestionContainer = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 25px;
    justify-items: start;
`;

export const QuestionTitle = styled.div`
    font-family: PoppinsMedium;
    font-size: 16px;
`;
