import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

export const Virus1 = styled.img`
    position: fixed;
    top: 6%;
    z-index: 0;

    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.M} {
        display: revert;
    }
`;

export const Virus2 = styled.img`
    position: fixed;
    top: 50%;
    left: 2%;
    width: 155px;
    z-index: 0;

    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.M} {
        display: revert;
    }
`;

export const Virus3 = styled.img`
    position: fixed;
    top: 79%;
    right: 5%;
    width: 120px;
    z-index: 0;

    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.M} {
        display: revert;
    }
`;

export const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 5;
    background-color: ${colours.white};

    @media ${sizes.XP} {
        margin: 50px 10px 15px 10px;
    }

    @media ${sizes.M} {
        margin: 50px 20%;
    }
`;

export const TopText = styled.div`
    font-family: NunitoBold;
    color: ${colours.purple};
    text-align: center;

    @media ${sizes.XP} {
        font-size: 24px;
        margin-bottom: 20px;
    }

    @media ${sizes.M} {
        font-size: 26px;
        margin-bottom: 40px;
    }
`;

export const FormContainer = styled.div`
    width: 100%;
    overflow-y: scroll;
    border: solid 1px ${colours.mediumGrey};
    border-radius: 6px;

    @media ${sizes.XP} {
        height: 300px;
    }

    @media ${sizes.M} {
        height: 490px;
    }
`;

export const PageIMG = styled.img`
    width: 100%;
    margin-bottom: 10px;
    box-shadow: 0 5px 5px ${colours.mediumGrey};
`;

export const DownloadPDF = styled(Link)`
    text-align: end;
    text-decoration: none;
    font-family: NunitoBold;
    color: ${colours.purple};

    @media ${sizes.XP} {
        font-size: 16px;
    }

    @media ${sizes.M} {
        font-size: 18px;
    }
`;

export const BottomText = styled.div`
    margin: 18px 0;
    font-family: PoppinsRegular;

    @media ${sizes.XP} {
        font-size: 16px;
    }

    @media ${sizes.M} {
        font-size: 18px;
    }
`;

export const RadioContainer = styled.div`
    display: flex;
    font-family: PoppinsRegular;
`;

export const Disclaimer = styled.div`
    @media ${sizes.XP} {
        font-size: 14px;
    }

    @media ${sizes.M} {
        font-size: 12px;
    }
`;

export const IndicateConsent = styled.div`
    font-family: PoppinsMedium;
    margin-top: 45px;

    @media ${sizes.XP} {
        font-size: 16px;
    }

    @media ${sizes.M} {
        font-size: 18px;
    }
`;
