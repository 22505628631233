/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Question } from '../../constants/questionTypes';
import { saveObject } from '../../slices/surveySlice';

import { OptionContainer, OptionLabel } from '../CheckboxQuestion/styles';

import Loader from '../Loader';
import { CustomRadio, HiddenRadio } from '../RadioQuestion/styles';

import {
    Limits,
    MetricSelectorContainer,
    Minus,
    NumberFieldContainer,
    NumberInput,
    NumberInputContainer,
    Plus,
    Rounded,
    WeightHeightContainer,
} from './styles';

export interface IProps {
    question: number;
    text: string;
    questionOptionId?: number;
    index?: number;
    min?: number | null;
    max?: number | null;
    value: number | null;
    subtype?: string | null;
}

export const NumberQuestion: React.FunctionComponent<IProps> = ({
    question,
    text,
    min,
    max,
    questionOptionId,
    value,
    index,
    subtype,
}) => {
    const [answer, setAnswer] = useState<number>(0);
    const [isMetric, setIsMetric] = useState<boolean>(true);

    useEffect(() => {
        if (value) {
            setAnswer(value);

            if (index) {
                dispatch(
                    saveObject({
                        question_id: question,
                        answer: value,
                        answer_offered_id: questionOptionId,
                        order: index,
                        type: Question.number,
                    }),
                );
            }
        }
    }, [value]);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleChange = (question: number, newAnswer: number) => {
        let val = newAnswer;
        if (min && newAnswer < min) {
            val = min;
        } else if (max && newAnswer > max) {
            val = max;
        }

        setAnswer(val);

        if (subtype === 'weight' && !isMetric) {
            val = Math.round(val * 0.45359237);
        } else if (subtype === 'height' && !isMetric) {
            val = Math.round(val * 2.54);
        }

        dispatch(
            saveObject({
                question_id: question,
                answer: val.toString(),
                answer_offered_id: questionOptionId,
                order: index,
                type: Question.number,
            }),
        );
    };

    const handleMinus = () => {
        const newAnswer = answer;
        if ((min?.toString() && answer > min) || answer > 1) {
            setAnswer(answer - 1);
            handleChange(question, newAnswer - 1);
        }
    };

    const handlePlus = () => {
        const newAnswer = answer;
        if (max && answer < max) {
            setAnswer(answer + 1);
            handleChange(question, newAnswer + 1);
        } else {
            setAnswer(answer + 1);
            handleChange(question, newAnswer + 1);
        }
    };

    // Loader
    if (answer)
        if (!max && !subtype) {
            return <Loader />;
        }

    // Normal NumberQuestion (no subtype)
    if (min?.toString() && max) {
        return (
            <div>
                <Limits>
                    {index ? `${t(`survey.${text}`)}. ` : ''}
                    {t('survey.chooseBetween', { min, max })}
                </Limits>
                <NumberInputContainer margin={index ? '0' : 'auto'}>
                    <Minus onClick={handleMinus} />
                    <NumberInput
                        type="number"
                        name={text}
                        value={answer}
                        min={min}
                        max={max}
                        onChange={(event) => handleChange(question, Number(event.target.value))}
                    />
                    <Plus onClick={handlePlus} />
                </NumberInputContainer>
            </div>
        );
    }

    // Weight
    if (subtype === 'weight') {
        return (
            <WeightHeightContainer>
                <MetricSelectorContainer>
                    <OptionContainer key="weight-kg">
                        <CustomRadio checked={isMetric} onClick={() => setIsMetric(true)} />
                        <HiddenRadio
                            type="radio"
                            name="kg"
                            id="kg"
                            value="kg"
                            onChange={() => setIsMetric(true)}
                        />
                        <OptionLabel htmlFor="kg">{'Kg'}</OptionLabel>
                    </OptionContainer>
                    <OptionContainer key="weight-lb">
                        <CustomRadio checked={!isMetric} onClick={() => setIsMetric(false)} />
                        <HiddenRadio
                            type="radio"
                            name="lb"
                            id="lb"
                            value="lb"
                            onChange={() => setIsMetric(false)}
                        />
                        <OptionLabel htmlFor="lb">{'Lbs'}</OptionLabel>
                    </OptionContainer>
                </MetricSelectorContainer>
                <NumberFieldContainer>
                    <NumberInputContainer>
                        <Minus onClick={handleMinus} />
                        <NumberInput
                            type="number"
                            name={text}
                            value={answer}
                            min={1}
                            max={500}
                            onChange={(event) => handleChange(question, Number(event.target.value))}
                        />
                        <Plus onClick={handlePlus} />
                    </NumberInputContainer>
                    <Rounded>{t('survey.rounded')}</Rounded>
                </NumberFieldContainer>
            </WeightHeightContainer>
        );
    }

    // Height
    if (subtype === 'height') {
        return (
            <WeightHeightContainer>
                <MetricSelectorContainer>
                    <OptionContainer key="height">
                        <CustomRadio checked={isMetric} onClick={() => setIsMetric(true)} />
                        <HiddenRadio
                            type="radio"
                            name="cm"
                            id="cm"
                            value="cm"
                            onChange={() => setIsMetric(true)}
                        />
                        <OptionLabel htmlFor="cm">{'cm'}</OptionLabel>
                    </OptionContainer>
                    <OptionContainer key="height">
                        <CustomRadio checked={!isMetric} onClick={() => setIsMetric(false)} />
                        <HiddenRadio
                            type="radio"
                            name="in"
                            id="in"
                            value="in"
                            onChange={() => setIsMetric(false)}
                        />
                        <OptionLabel htmlFor="in">{'in'}</OptionLabel>
                    </OptionContainer>
                </MetricSelectorContainer>
                <NumberFieldContainer>
                    <NumberInputContainer>
                        <Minus onClick={handleMinus} />
                        <NumberInput
                            type="number"
                            name={text}
                            value={answer}
                            min={1}
                            max={500}
                            onChange={(event) => handleChange(question, Number(event.target.value))}
                        />
                        <Plus onClick={handlePlus} />
                    </NumberInputContainer>
                    <Rounded>{t('survey.rounded')}</Rounded>
                </NumberFieldContainer>
            </WeightHeightContainer>
        );
    }

    return <Loader />;
};
