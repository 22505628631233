import styled from 'styled-components';

import { sizes } from '../../constants/mediaSizes';
import { colours } from '../../constants/colours';

export interface IButtonPage {
    active: boolean;
}

export const AdminPageContainer = styled.div`
    display: grid;
    height: 100vh;
    place-items: center;

    @media ${sizes.XP} {
        grid-template-rows: 120px 120px 70px auto 70px 70px;
    }

    @media ${sizes.G} {
        grid-template-rows: 77px 70px 70px auto 77px 77px;
    }
`;

export const ListHeader = styled.div`
    display: flex;
    width: 90%;
    align-items: flex-end;
`;

export const TotalRecords = styled.div`
    font-family: 'PoppinsRegular';
    font-size: 16px;
    margin-left: 16px;
`;

export const SearchInput = styled.input`
    border-radius: 25px;
    width: 250px;

    &:focus {
        outline: none;
    }
`;

export const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 16px;
`;

export const RadioButton = styled.input`
    margin-left: 10px;
    margin-right: 8px;
`;

export const ButtonPage = styled.span<IButtonPage>`
    cursor: pointer;
    font-family: 'NunitoSemiBold';
    font-size: 18px;
    margin: 10px;
    color: ${(props) => (props.active ? colours.purple : colours.mediumGrey)};
`;
