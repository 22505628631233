import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { signout } from '../../slices/authSlice';

import EncoreLogoFR from '../../assets/icons/encore_logo_min_fr.svg';
import EncoreLogoEN from '../../assets/icons/encore_logo_min_en.svg';
import LeftArrow from '../../assets/icons/left_arrow_icon.svg';

import {
    HeaderContainer,
    HeaderLogo,
    LogoutButton,
    PreviousButton,
    TitleDesktop,
    TitleMobile,
    Spacer,
    MobileLogo,
} from './styles';
import { LanguageSwitch, LanguageSwitchContainer } from '../../styles/common';

export interface IProps {
    question?: number;
    section?: number;
    sectionName?: string;
    sectionLength?: number;
    onPrevious?: () => void;
}

export const Header: React.FunctionComponent<IProps> = ({
    question,
    section,
    sectionName,
    sectionLength,
    onPrevious,
}) => {
    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language
    const history = useHistory();
    const dispatch = useDispatch();

    // Language switch
    const changeLanguage = async (lng: string) => {
        await i18n.changeLanguage(lng);
    };

    // Changes logo according to language
    const encoreLogo = language === 'en' ? EncoreLogoEN : EncoreLogoFR;

    if (section && sectionName && sectionLength && question) {
        return (
            <HeaderContainer
                percentage={question / sectionLength}
                firstQuestion={section === 1 && question === 1}
            >
                {(question > 1 || section > 1) && (
                    <PreviousButton onClick={onPrevious}>
                        <img src={LeftArrow} alt={t('footer.previous')} />
                    </PreviousButton>
                )}

                <HeaderLogo
                    onClick={() => {
                        history.push('/');
                    }}
                    src={encoreLogo}
                    alt={t('homepage.logoAlt')}
                />
                <TitleDesktop>
                    {t('header.titleDesktop', { section, sectionName: t(`header.${sectionName}`) })}
                </TitleDesktop>
                <TitleMobile>
                    <div>{t('header.titleMobile', { section })}</div>
                    <div>{t(`header.${sectionName}`)}</div>
                </TitleMobile>
                <LogoutButton
                    onClick={() =>
                        dispatch(
                            signout(() => {
                                history.push('/login');
                            }),
                        )
                    }
                >
                    {t('header.saveAndContinueLater')}
                </LogoutButton>
                <LanguageSwitchContainer>
                    <LanguageSwitch
                        type="button"
                        onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')}
                    >
                        {language === 'en' ? 'Fr' : 'En'}
                    </LanguageSwitch>
                </LanguageSwitchContainer>
            </HeaderContainer>
        );
    }

    return (
        <HeaderContainer percentage={0} firstQuestion={section === 1 && question === 1}>
            <HeaderLogo
                onClick={() => {
                    history.push('/');
                }}
                src={encoreLogo}
                alt={t('homepage.logoAlt')}
            />
            <Spacer></Spacer>
            <MobileLogo src={encoreLogo} alt={t('homepage.logoAlt')} />
            <LogoutButton />
            <LanguageSwitchContainer>
                <LanguageSwitch
                    type="button"
                    onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')}
                >
                    {language === 'en' ? 'Fr' : 'En'}
                </LanguageSwitch>
            </LanguageSwitchContainer>
        </HeaderContainer>
    );
};
