/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { Question } from '../../constants/questionTypes';

import { QuestionOptionType, saveObject } from '../../slices/surveySlice';
import { Input, InputContainer, InputTitle } from '../SelectboxQuestion/styles';
import {
    Subtitle,
    CustomCheckbox,
    HiddenCheckbox,
    OptionContainer,
    OptionLabel,
    OptionsContainer,
    CheckboxContainer,
} from './styles';

export interface IProps {
    question: number;
    text: string;
    index?: number;
    options: QuestionOptionType[];
    value?: string;
}

export interface IAnswerObject {
    question_id: number;
    answer_offered_id: number;
    user_id?: string;
    is_custom: boolean;
    custom_answer?: string;
}

export const CheckboxQuestion: React.FunctionComponent<IProps> = ({
    question,
    text,
    index,
    options,
    value,
}) => {
    const [answers, setAnswers] = useState<Array<string | number>>([]);
    const [userAnswer, setUserAnswer] = useState<string>('');
    const [selfDescribe, setSelfDescribe] = useState<boolean>(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const answerObjects = useRef<IAnswerObject[] | null>(null);

    useEffect(() => {
        let answersValues;
        if (index) {
            answersValues = options.filter(
                (option) =>
                    option.selected.length > 0 &&
                    option.selected.findIndex((elm: any) => elm.order === index) !== -1,
            );
        } else {
            answersValues = options.filter((option) => option.selected.length > 0);
        }

        setAnswers(answersValues.map((option) => option.id));

        for (const answer of answersValues) {
            dispatch(
                saveObject({
                    answer_offered_id: answer.selected[0].answerOfferedId,
                    type: Question.checkbox,
                    question_id: question,
                    user_id: answer.selected[0].userId,
                    order: index,
                    custom_answer: answer.selected[0].custom_answer
                        ? answer.selected[0].custom_answer.answerText
                        : null,
                }),
            );
        }

        answerObjects.current = answersValues.map((answer: QuestionOptionType) => {
            return {
                question_id: question,
                answer_offered_id: Number(answer.id),
                is_custom: Boolean(answer.selected[0].custom_answer),
                order: index,
                type: Question.checkbox,
            };
        });

        const userAnswer = options.filter((option) => {
            const isOptionSelected = option.selected && option.selected.length;
            const hasCustomAnswer =
                isOptionSelected &&
                option.selected[0].custom_answer &&
                option.selected[0].custom_answer.answerText !== '';
            return hasCustomAnswer;
        });

        if (userAnswer.length > 0) {
            // by default the answer comes in the first position
            let answer = ((userAnswer[0].selected[0] ?? {}).custom_answer ?? {}).answerText;

            // check if there is any custom answer selected and activate the custom field
            setSelfDescribe(
                options.filter((option) => option.customAnswer && option.selected.length > 0)
                    .length > 0,
            );

            // if it comes from the multitype question
            if (index) {
                // get the answer from the right "section"
                // using the order. The order indicates in which section the answer is.
                answer = userAnswer[0].selected.filter((elm: any) => elm.order === index)[0]
                    ?.custom_answer?.answerText;

                // just activate the field if there is any answer
                setSelfDescribe(typeof answer !== 'undefined');
            }
            // set the answer to the field
            setUserAnswer(answer);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    const handleChange = (question: number, answer: string, customAnswer: boolean) => {
        const answerIndex = answers.findIndex(
            (thisAnswer) => answer.toString() === thisAnswer.toString(),
        );
        const newAnswersArray = [...answers];

        if (answerIndex !== -1) {
            newAnswersArray.splice(answerIndex, 1);
        } else {
            newAnswersArray.push(answer);
        }

        setAnswers(newAnswersArray);

        const customOptionIndex = options.findIndex((option) => option.customAnswer);
        const customOption = options[customOptionIndex];

        answerObjects.current = newAnswersArray.map((answer: any) => {
            return {
                question_id: question,
                answer_offered_id: Number(answer),
                is_custom: customOptionIndex !== -1 ? customOption.id === Number(answer) : false,
                order: index,
                type: Question.checkbox,
            };
        });

        dispatch(
            saveObject({
                question_id: question,
                answer_offered_id: Number(answer),
                is_custom: customAnswer,
                order: index,
                type: Question.checkbox,
            }),
        );
    };

    const handleCustomAnswerChange = (value: string) => {
        setUserAnswer(value);

        if (Array.isArray(answerObjects.current)) {
            dispatch(
                saveObject(
                    answerObjects.current.map((answer) => {
                        if (answer.is_custom) {
                            return {
                                ...answer,
                                custom_answer: value,
                            };
                        }
                        return { type: Question.checkbox, order: index, ...answer };
                    }),
                ),
            );
        }
    };

    return (
        <CheckboxContainer>
            {index && <Subtitle>{t(`survey.${text}`)}</Subtitle>}
            {!index && <Subtitle>{t(`survey.selectMany`)}</Subtitle>}
            <OptionsContainer>
                {options.map((option) => {
                    return (
                        <OptionContainer key={option.text}>
                            <HiddenCheckbox
                                id={option.text}
                                title={option.text}
                                type="checkbox"
                                name={text}
                                value={option.text}
                                onChange={() => {
                                    if (option.customAnswer) {
                                        setSelfDescribe(!selfDescribe);
                                    }
                                    handleChange(
                                        question,
                                        option.id.toString(),
                                        option.customAnswer,
                                    );
                                }}
                            />
                            <CustomCheckbox
                                checked={answers.some(
                                    (answer) => answer.toString() === option.id.toString(),
                                )}
                                onClick={() => {
                                    if (option.customAnswer) {
                                        setSelfDescribe(!selfDescribe);
                                    }
                                    handleChange(
                                        question,
                                        option.id.toString(),
                                        option.customAnswer,
                                    );
                                }}
                            />
                            <OptionLabel htmlFor={option.text}>
                                {t(`survey.${option.text}`)}
                            </OptionLabel>
                        </OptionContainer>
                    );
                })}
                {selfDescribe && (
                    <InputContainer>
                        <InputTitle>{t(`survey.specify`)}</InputTitle>
                        <Input
                            type="text"
                            name={text}
                            value={userAnswer}
                            placeholder={t(`survey.typetexthere`)}
                            // onChange={(event) => setUserAnswer(event.target.value)}
                            onChange={(event) => handleCustomAnswerChange(event.target.value)}
                            // onBlur={() => handleSelfDescribe(question, userAnswer)}
                        />
                    </InputContainer>
                )}
            </OptionsContainer>
        </CheckboxContainer>
    );
};
