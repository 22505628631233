/* eslint-disable @typescript-eslint/no-floating-promises */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../constants/routes';
import { whoami } from './authSlice';

export interface IError {
    message: string;
}

export interface IUpdateProfileResponseSuccess {
    data: {
        id: number;
        email: string;
        password?: string;
        profile: IUserUpdateProfile | null;
    };
}

export interface IUserUpdateProfile {
    id: number;
    firstName: string;
    lastName: string;
    postalCode: string;
    firstAddressLine: string;
    secondAddressLine: string;
    city: string;
    province: string;
    language: string;
    phone: string;
    testKitSent: boolean;
    sampleReceivedAtLab: string;
    labStatusResult: string;
}

export const updateProfile = createAsyncThunk(
    'update/profile',
    async (
        params: {
            id: number;
            email: string;
            password?: string;
            profile: {
                id: number;
                firstName: string;
                lastName: string;
                postalCode: string;
                firstAddressLine: string;
                secondAddressLine: string;
                city: string;
                province: string;
            };
        },
        { dispatch, rejectWithValue },
    ) => {
        const headers = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        };
        const valuesToUpdate = {
            id: params.id,
            email: params.email,
            password: params.password,
            profile: {
                id: params.profile.id,
                firstName: params.profile.firstName,
                lastName: params.profile.lastName,
                postalCode: params.profile.postalCode,
                firstAddressLine: params.profile.firstAddressLine,
                secondAddressLine: params.profile.secondAddressLine,
                city: params.profile.city,
                province: params.profile.province,
            },
        };
        try {
            const response = await axios.put<IUpdateProfileResponseSuccess>(
                BACKEND_ROUTES.UPDATE_PROFILE,
                valuesToUpdate,
                headers,
            );
            dispatch(whoami());
            return response;
        } catch (e) {
            const error = e as AxiosError<string>;
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    },
);
