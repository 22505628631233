/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { RootState } from '../store';

import { BACKEND_ROUTES } from '../constants/routes';

export interface IError {
    message: string;
}

export interface ILoginResponseSuccess {
    data: {
        user: IUser;
        token: string | null;
    };
}
export interface IUser {
    id: number;
    role: string;
    email: string;
    status: number;
    createdAt: string;
    profileId?: number;
    password?: string;
    profile: IUserProfile;
}

export interface IUserProfile {
    id: number;
    firstName: string;
    lastName: string;
    postalCode: string;
    firstAddressLine: string;
    secondAddressLine: string;
    city: string;
    province: string;
    language: string;
    phone: string;
    testKitSent: boolean;
    sampleReceivedAtLab: string;
    labStatusResult: string;
}
export interface IAuthState {
    user: IUser | null;
    errors: IError | string | null;
    loading: boolean;
}

export interface ILoginResponseSuccess {
    data: {
        user: IUser;
        token: string | null;
    };
}

export const initialState: IAuthState = {
    user: null,
    errors: null,
    loading: false,
};

export const loginUser = createAsyncThunk(
    'auth/login',
    async (
        params: {
            email: string;
            password: string;
        },
        { dispatch },
    ) => {
        try {
            const response = await axios.post(BACKEND_ROUTES.LOGIN, {
                email: params.email,
                password: params.password,
            });
            const apiToken: string = response.data.data.token ? response.data.data.token : '';
            localStorage.setItem('token', apiToken);
            dispatch(setUserAction(response.data));
        } catch (error) {
            const errorText = error.response.data as string;
            dispatch(setErrorsAction(errorText));
        }
    },
);

export const whoami = createAsyncThunk('auth/whoami', async (_, { dispatch }) => {
    try {
        const response = await axios.get(BACKEND_ROUTES.WHOAMI, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        const user = {
            data: {
                token: localStorage.getItem('token'),
                user: response.data.data,
            },
        };
        dispatch(setUserAction(user));
    } catch (error) {
        localStorage.removeItem('token');
        dispatch(setUserAction(null));
        const errorText = error.response.data as string;
        dispatch(setErrorsAction(errorText));
    }
});

// When user logs out, clear api token from local storage and set store to initial state
export const logoutUser = createAsyncThunk('auth/logout', (_, { dispatch }) => {
    localStorage.removeItem('token');
    dispatch(setUserAction(null));
});

export const signout = createAsyncThunk<any, any, { rejectValue: string }>(
    'get/signout',
    (executeRoute, { getState, dispatch }) => {
        const state: any = getState();
        localStorage.setItem(
            'last_question',
            JSON.stringify({
                question_id: state.survey.currentQuestion,
            }),
        );
        localStorage.removeItem('token');
        dispatch(setUserAction(null));
        executeRoute();
    },
);

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserAction(state, action: PayloadAction<ILoginResponseSuccess | null>) {
            state.user = action.payload?.data?.user ? action.payload.data.user : null;
            state.loading = false;
            state.errors = null;
        },
        setErrorsAction(state, action) {
            state.user = null;
            state.errors = action.payload as string;
            state.loading = false;
        },
    },
});

export const selectUser = (state: RootState) => state.auth.user;

export const { setUserAction, setErrorsAction } = slice.actions;

export default slice.reducer;
