import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { selectUser } from './slices/authSlice';

const PrivateRoute: React.FC<RouteProps & { isAdminRoute?: boolean }> = ({
    children,
    isAdminRoute,
    ...rest
}) => {
    const user = useSelector(selectUser);

    const renderRedirect = (pathname: string) => {
        return (
            <Route
                {...rest}
                render={({ location }) => (
                    <Redirect
                        to={{
                            pathname,
                            state: { from: location },
                        }}
                    />
                )}
            />
        );
    };

    // if admin trying to access survey
    if ((user?.role === 'admin' || user?.role === 'coordinator') && !isAdminRoute) {
        return renderRedirect('/admin');
    }

    // if non-admin trying to access admin
    if (user?.role !== 'admin' && user?.role !== 'coordinator' && isAdminRoute) {
        return renderRedirect('/survey');
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                localStorage.getItem('token') ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
};
export default PrivateRoute;
