import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    createUser,
    clearCreateUser,
    selectCreateUser,
    selectCreateUserError,
} from '../../slices/createUserSlice';

import CloseIcon from '../../assets/icons/close_icon.svg';
import {
    Modal,
    ModalContainer,
    Cancel,
    Send,
    CloseButton,
    Title,
    Label,
    Field,
    FieldContainer,
    ButtonsContainer,
} from '../../styles/modal';
import { FieldError, RadioGroup } from '../../styles/common';
import { validateFirstName, validateLastName, validateEmail } from '../../utils/validation';
import { RadioOption } from '../RadioOption';

export interface IProps {
    onCloseButton: (close: boolean) => void;
}

export const AdminCreateUserModal: React.FunctionComponent<IProps> = ({ onCloseButton }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const roles: string[] = ['admin', 'coordinator'];

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [role, setRole] = useState<string>('admin');
    const [invalidFirstName, setFirstNameInvalid] = useState<boolean>(false);
    const [invalidLastName, setLastNameInvalid] = useState<boolean>(false);
    const [invalidEmail, setEmailInvalid] = useState<boolean>(false);

    const isFirstNameValid = (): boolean => validateFirstName(firstName);
    const isLastNameValid = (): boolean => validateLastName(lastName);
    const isEmailValid = (): boolean => validateEmail(email);

    const createdUser = useSelector(selectCreateUser);
    const createdUserError = useSelector(selectCreateUserError);

    const isFormValid = (): boolean => {
        return isFirstNameValid() && isLastNameValid() && isEmailValid();
    };

    const sendCreate = () => {
        if (!isFormValid()) {
            return;
        }

        dispatch(
            createUser({
                firstName,
                lastName,
                email,
                role,
            }),
        );
    };

    const handleRoleChange = (title: string) => {
        setRole(title);
    };

    // we've just created the user so let's clear it and exit
    if (createdUser && createdUser.email === email) {
        dispatch(clearCreateUser(null));
        onCloseButton(false);
        return null;
    }

    return (
        <ModalContainer>
            <Modal>
                <CloseButton onClick={() => onCloseButton(false)}>
                    <img src={CloseIcon} alt="close" />
                </CloseButton>
                <Title>{t('admin.createUserModal.title')}</Title>
                <FieldContainer>
                    <Label>{t('admin.createUserModal.firstName')}</Label>
                    <Field
                        type="text"
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        onBlur={() => setFirstNameInvalid(!isFirstNameValid())}
                    ></Field>
                    {invalidFirstName && (
                        <FieldError>{t('admin.createUserModal.invalidFirstName')}</FieldError>
                    )}
                </FieldContainer>
                <FieldContainer>
                    <Label>{t('admin.createUserModal.lastName')}</Label>
                    <Field
                        type="text"
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        onBlur={() => setLastNameInvalid(!isLastNameValid())}
                    ></Field>
                    {invalidLastName && (
                        <FieldError>{t('admin.createUserModal.invalidLastName')}</FieldError>
                    )}
                </FieldContainer>
                <FieldContainer>
                    <Label>{t('admin.createUserModal.email')}</Label>
                    <Field
                        type="email"
                        value={email}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                        onBlur={() => setEmailInvalid(!isEmailValid())}
                    ></Field>
                    {invalidEmail && (
                        <FieldError>{t('admin.createUserModal.invalidEmail')}</FieldError>
                    )}
                </FieldContainer>
                <FieldContainer>
                    <Label>{t('admin.createUserModal.role')}</Label>
                    <RadioGroup>
                        {[
                            [t('admin.createUserModal.titleAdministrator'), roles[0]],
                            [t('admin.createUserModal.titleCoordinator'), roles[1]],
                        ].map(([text, value]) => (
                            <RadioOption
                                key={value}
                                handleChange={() => handleRoleChange(value)}
                                name={value}
                                id={value}
                                value={value}
                                text={text}
                                checked={value === role}
                            />
                        ))}
                    </RadioGroup>
                </FieldContainer>
                <FieldError>{createdUserError}</FieldError>
                <ButtonsContainer>
                    <Cancel onClick={() => onCloseButton(false)}>{t('admin.cancel')}</Cancel>
                    <Send onClick={sendCreate}>{t('admin.send')}</Send>
                </ButtonsContainer>
            </Modal>
        </ModalContainer>
    );
};
