import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

export const Background = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
`;

export const Sky = styled.div`
    background-color: ${colours.lightBlue};
    height: 65%;
`;

export const Ground = styled.div`
    background-color: ${colours.greenGrass};
    height: 35%;
`;

export const IllustrationContainer = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
    z-index: 10;
    position: relative;
`;

export const Illustration = styled.img`
    width: 85%;

    @media ${sizes.G} {
        position: absolute;
        bottom: 20%;
    }

    @media ${sizes.XG} {
        position: revert;
    }
`;
