/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Question } from '../../constants/questionTypes';

import { QuestionOptionType, saveObject } from '../../slices/surveySlice';

import { Textfield, Label, Container } from './styles';

export interface IProps {
    question: number;
    text: string;
    index?: number;
    questionOptionId?: number;
    value?: any;
    options?: QuestionOptionType[];
    mask?: string;
}

export const TextfieldQuestion: React.FC<IProps> = ({
    question,
    text,
    index,
    questionOptionId,
    value,
    mask = '',
}) => {
    const [answer, setAnswer] = useState<string>(value && value.length > 0 ? value[0].answer : '');

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { control } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldFocusError: true,
    });

    const handleChange = (question: number, answer: string) => {
        setAnswer(answer);
        dispatch(
            saveObject({
                question_id: question,
                answer,
                answer_offered_id: questionOptionId,
                order: index,
                type: Question.text,
            }),
        );
    };

    useEffect(() => {
        if (index && value && value.length > 0) {
            dispatch(
                saveObject({
                    question_id: question,
                    answer: value[0].answer,
                    answer_offered_id: questionOptionId,
                    order: index,
                    type: Question.text,
                }),
            );
        }
    }, []);

    return (
        <React.Fragment>
            <Controller
                render={() => (
                    <Container>
                        <Label>{t(`label.${text}`)}</Label>
                        <Textfield
                            type="text"
                            mask={mask}
                            name={text}
                            value={answer}
                            onChange={(event) => handleChange(question, event.target.value)}
                        />
                    </Container>
                )}
                control={control}
                value={answer}
                name={text}
            />
        </React.Fragment>
    );
};
