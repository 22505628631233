import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

export const PageContainer = styled.div`
    display: grid;
    height: 100vh;
    place-items: center;

    @media ${sizes.XP} {
        grid-template-rows: 120px auto;
    }

    @media ${sizes.G} {
        grid-template-rows: 77px auto;
    }
`;

export const ThankYouContainer = styled.div`
    display: grid;
    place-items: center;
    height: 100%;

    @media ${sizes.XP} {
        margin: 0 20px;
    }

    @media ${sizes.M} {
        margin: 0;
    }
`;

export const Image = styled.img`
    @media ${sizes.XP} {
        padding: 10px;
    }

    @media ${sizes.M} {
        padding: 0;
    }
`;

export const Text1 = styled.div`
    font-family: PoppinsRegular;
    font-size: 18px;
    text-align: center;

    @media ${sizes.M} {
        max-width: 620px;
    }
`;

export const Text2 = styled.div`
    font-family: PoppinsRegular;
    font-size: 18px;
    text-align: center;
`;

export const Link = styled.a`
    font-family: PoppinsMedium;
    color: ${colours.purple};
    text-decoration: none;
`;
