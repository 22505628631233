import styled from 'styled-components';

import { sizes } from '../../constants/mediaSizes';

export const Text = styled.div`
    margin: 15px 0;
`;

export const Container = styled.div`
    display: grid;
    place-items: center;

    @media ${sizes.XP} {
        grid-template-rows: auto 15% auto auto 12% 12% auto;
    }

    @media ${sizes.G} {
        grid-template-rows: 77px 15% auto auto 10% 10% auto;
    }
`;
