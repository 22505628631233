/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { Select } from '../DropdownQuestion/styles';
import { SelectboxButton } from '../SelectboxQuestion/styles';
import { DateContainer, GroupedQuestionContainer, Datehint } from './styles';

import { QuestionOptionType, saveObject } from '../../slices/surveySlice';

import { Question } from '../../constants/questionTypes';

export interface IProps {
    question: number;
    options?: QuestionOptionType[];
    questionOptionId?: number;
    value?: any;
    index?: number;
    text?: string;
}

export const GroupedQuestion: React.FunctionComponent<IProps> = ({
    question,
    options,
    questionOptionId,
    value,
    text,
    index,
}) => {
    const dispatch = useDispatch();

    const [period, setPeriod] = useState<string>('');
    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [noDate, setNoDate] = useState<boolean>(false);

    const { t } = useTranslation();

    const handleChange = (value: string) => {
        setNoDate(false);
        setYear(value);
        dispatch(
            saveObject({
                question_id: question,
                answer: `${period}-${month}-${value}`,
                answer_offered_id: questionOptionId,
                order: index,
                type: Question.grouped,
            }),
        );
    };

    const handleNoDate = () => {
        setNoDate(!noDate);
        setPeriod('');
        setMonth('');
        setYear('');
        dispatch(
            saveObject({
                question_id: question,
                answer: `dontKnow`,
                answer_offered_id: questionOptionId,
                order: index,
                type: Question.grouped,
            }),
        );
    };

    useEffect(() => {
        if (value && value.length > 0) {
            const { answer } = value[0];
            if (answer === 'dontKnow') {
                setNoDate(true);

                if (index) {
                    dispatch(
                        saveObject({
                            question_id: question,
                            answer: `dontKnow`,
                            answer_offered_id: questionOptionId,
                            order: index,
                            type: Question.grouped,
                        }),
                    );
                }
            } else {
                const answerArray = answer.split('-');
                setPeriod(answerArray[0]);
                setMonth(answerArray[1]);
                setYear(answerArray[2]);

                // In case of multiple types, we need to add to the store the loaded answers so we don't lose their values in edition
                if (index) {
                    dispatch(
                        saveObject({
                            question_id: question,
                            answer: `${answerArray[0]}-${answerArray[1]}-${answerArray[2]}`,
                            answer_offered_id: questionOptionId,
                            order: index,
                            type: Question.grouped,
                        }),
                    );
                }
            }
        }
    }, [value]);

    return (
        <GroupedQuestionContainer>
            {text && <Datehint>{t(`survey.${text}`)}</Datehint>}
            <DateContainer>
                <Select value={period} onChange={(event) => setPeriod(event.currentTarget.value)}>
                    <option key={''} value={''} />
                    <option key="early" value="early">
                        {t('survey.early')}
                    </option>
                    <option key="mid" value="mid">
                        {t('survey.mid')}
                    </option>
                    <option key="late" value="late">
                        {t('survey.late')}
                    </option>
                </Select>
                <Select value={month} onChange={(event) => setMonth(event.currentTarget.value)}>
                    <option key={''} value={''} />
                    <option key="1" value="01">
                        {t('survey.jan')}
                    </option>
                    <option key="2" value="02">
                        {t('survey.feb')}
                    </option>
                    <option key="3" value="03">
                        {t('survey.mar')}
                    </option>
                    <option key="4" value="04">
                        {t('survey.apr')}
                    </option>
                    <option key="5" value="05">
                        {t('survey.may')}
                    </option>
                    <option key="6" value="06">
                        {t('survey.jun')}
                    </option>
                    <option key="7" value="07">
                        {t('survey.jul')}
                    </option>
                    <option key="8" value="08">
                        {t('survey.aug')}
                    </option>
                    <option key="9" value="09">
                        {t('survey.sep')}
                    </option>
                    <option key="10" value="10">
                        {t('survey.oct')}
                    </option>
                    <option key="11" value="11">
                        {t('survey.nov')}
                    </option>
                    <option key="12" value="12">
                        {t('survey.dec')}
                    </option>
                </Select>
                <Select value={year} onChange={(event) => handleChange(event.currentTarget.value)}>
                    <option key={''} value={''} />
                    <option key="2020" value="2020">
                        2020
                    </option>
                    <option key="2021" value="2021">
                        2021
                    </option>
                </Select>
            </DateContainer>
            <div>
                <SelectboxButton
                    selected={noDate}
                    onClick={handleNoDate}
                    stringLength={Number(t('survey.dontKnow').length)}
                >
                    {t('survey.dontKnow')}
                </SelectboxButton>
            </div>
        </GroupedQuestionContainer>
    );
};
