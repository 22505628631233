import styled from 'styled-components';

import { sizes } from '../../constants/mediaSizes';

import DesktopNormal from '../../assets/icons/radio_normal_desktop.svg';
import DesktopHover from '../../assets/icons/radio_hover_desktop.svg';
import DesktopChecked from '../../assets/icons/radio_checked_desktop.svg';
import MobileNormal from '../../assets/icons/radio_normal_mobile.svg';
import MobileHover from '../../assets/icons/radio_hover_mobile.svg';
import MobileChecked from '../../assets/icons/radio_checked_mobile.svg';
import { OptionContainer } from '../CheckboxQuestion/styles';
import { colours } from '../../constants/colours';

export const HiddenRadio = styled.input.attrs({ type: 'radio' })`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const CustomRadio = styled.div<{ checked: boolean }>`
    display: inline-block;
    background-size: cover;

    ${OptionContainer}:hover + & {
        @media ${sizes.XP} {
            background-image: url(${MobileHover});
        }

        @media ${sizes.M} {
            background-image: url(${DesktopHover});
        }
    }

    ${HiddenRadio}:hover + & {
        @media ${sizes.XP} {
            background-image: url(${MobileHover});
        }

        @media ${sizes.M} {
            background-image: url(${DesktopHover});
        }
    }

    @media ${sizes.XP} {
        width: 23px;
        height: 23px;
        background-image: url(${(props) => (props.checked ? MobileChecked : MobileNormal)});
        padding-right: 21px;
    }

    @media ${sizes.M} {
        width: 18px;
        height: 18px;
        padding: 0;
        background-image: url(${(props) => (props.checked ? DesktopChecked : DesktopNormal)});
    }
`;

export const Label = styled.div`
    font-family: PoppinsMedium;
    color: ${colours.darkGrey};
`;
