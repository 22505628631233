import validator from 'validator';

const isNonEmptyString = (value: any): boolean => {
    return Boolean(isString(value) && value.length);
};

const isString = (value: any): boolean => {
    return Boolean(typeof value === 'string');
};

export const validateEmail = (email: string): boolean => {
    return typeof email === 'string' && validator.isEmail(email);
};

export const validateFirstName = (firstName: string): boolean => {
    return isNonEmptyString(firstName);
};

export const validateLastName = (lastName: string): boolean => {
    return isNonEmptyString(lastName);
};

export const validateRole = (role: string): boolean => {
    return Boolean(role === 'admin' || role === 'participant' || role === 'coordinator');
};

export const validatePostalCode = (postalCode: string): boolean => {
    return isNonEmptyString(postalCode);
};

export const validateFirstAddressLine = (firstAddressLine: string): boolean => {
    return isNonEmptyString(firstAddressLine);
};

export const validateSecondAddressLine = (secondAddressLine: string): boolean => {
    return isString(secondAddressLine);
};

export const validateCity = (city: string): boolean => {
    return isNonEmptyString(city);
};

export const validateProvince = (province: string): boolean => {
    return isNonEmptyString(province);
};

export const validatePhone = (phone: string): boolean => {
    return isNonEmptyString(phone);
};
