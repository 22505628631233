import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Routes from './Routes';
import Loader from './components/Loader';
import { selectUser, whoami } from './slices/authSlice';

import './fonts.css';

function App() {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    useEffect(() => {
        if (!user) {
            dispatch(whoami());
        }
    }, [dispatch, user]);

    return (
        <Suspense fallback={<Loader />}>
            <Routes />
        </Suspense>
    );
}

export default App;
