/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { QuestionOptionType, QuestionType, saveObject } from '../../slices/surveySlice';

import {
    Input,
    InputContainer,
    InputTitle,
    SelectboxButton,
    SelectboxContainer,
    SelectboxMainContainer,
} from './styles';

export interface IProps {
    question: QuestionType;
}

export interface ICustomAnswer {
    id: number;
    text: string;
}

export const SelectboxQuestion: React.FunctionComponent<IProps> = ({ question }) => {
    const [listAnswer, setListAnswer] = useState<string>('');
    const [customAnswer, setCustomAnswer] = useState<string>('');
    const [selfDescribe, setSelfDescribe] = useState<boolean>(false);

    const { question_option, id } = question;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const selectedId = useRef(-1);

    const handleChange = (option: QuestionOptionType) => {
        let isCustom = false;
        if (option.customAnswer) {
            setSelfDescribe(true);
            isCustom = true;
        } else {
            setSelfDescribe(false);
            isCustom = false;
        }
        setListAnswer(option.text);
        selectedId.current = option.id;

        dispatch(
            saveObject({
                question_id: id,
                answer_offered_id: option.id,
                is_custom: isCustom,
            }),
        );
    };

    const handleCustomAnswerChange = (value: string) => {
        setCustomAnswer(value);

        dispatch(
            saveObject({
                question_id: id,
                answer_offered_id: selectedId.current,
                custom_answer: value,
            }),
        );
    };

    useEffect(() => {
        const selectedOption = question.question_option.filter(
            (option) => option.selected.length > 0,
        );

        const answer = selectedOption[0] ?? '';

        setListAnswer(answer.text);
        selectedId.current = answer.id;
        const customAnswer = (((answer.selected ?? [])[0] ?? {}).custom_answer ?? {}).answerText;

        dispatch(
            saveObject({
                question_id: id,
                answer_offered_id: selectedId.current,
                custom_answer: customAnswer,
            }),
        );

        setCustomAnswer(customAnswer);
        setSelfDescribe(answer.customAnswer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [question]);

    return (
        <SelectboxMainContainer>
            <SelectboxContainer>
                {question_option.map((option) => {
                    return (
                        <SelectboxButton
                            key={option.text}
                            onClick={() => handleChange(option)}
                            selected={listAnswer === option.text}
                            stringLength={Number(t(`survey.${option.text}`).length)}
                        >
                            {t(`survey.${option.text}`)}
                        </SelectboxButton>
                    );
                })}
            </SelectboxContainer>
            {selfDescribe && (
                <InputContainer>
                    <InputTitle>{t(`survey.specify`)}</InputTitle>
                    <Input
                        type="text"
                        name={question.text}
                        placeholder={t(`survey.typetexthere`)}
                        value={customAnswer}
                        onChange={(event) => handleCustomAnswerChange(event.target.value)}
                    />
                </InputContainer>
            )}
        </SelectboxMainContainer>
    );
};
