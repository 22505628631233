export enum Question {
    checkbox = 'checkbox',
    radio = 'radio',
    text = 'text',
    date = 'date',
    dropdown = 'dropdown',
    number = 'number',
    select = 'select',
    multiradio = 'multiradio',
    multitype = 'multitype',
    grouped = 'groupedtype',
}

export enum QuestionTypeId {
    radio = 1,
    checkbox = 2,
    select = 3,
    dropdown = 4,
    text = 5,
    date = 6,
    multitype = 7,
    groupedtype = 8,
    number = 9,
    multiradio = 10,
}
