/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import { AdminHeader } from '../../components/AdminHeader';
import { AdminProfileModal } from '../../components/AdminProfileModal';
import { AdminPasswordModal } from '../../components/AdminPasswordModal';
import AdminDownloadHeader from '../../components/AdminDownloadHeader';
import { AdminCreateUserModal } from '../../components/AdminCreateUserModal';
import { AdminDeleteUserModal } from '../../components/AdminDeleteUserModal';
import { AdminParticipantInfoModal } from '../../components/AdminParticipantInfoModal';
import ListUsers from '../../components/ListUsers';
import Loader from '../../components/Loader';

// Redux
import { RootState } from '../../store';
import { fetchUsers, changePage } from '../../slices/adminSlice';
import { IUser } from '../../slices/authSlice';

// Constants
import { BACKEND_ROUTES } from '../../constants/routes';
import { HTTPDeleteRequest } from '../../utils/request';

// Hooks
import { useStateDebounced } from '../../hooks';

// Styles
import {
    AdminPageContainer,
    ButtonPage,
    ListHeader,
    SearchInput,
    TotalRecords,
    RadioButton,
    RadioContainer,
} from './styles';

const Admin: React.FunctionComponent = () => {
    const { t } = useTranslation();

    const [profileModalOpen, setProfileModalOpen] = useState<boolean>(false);
    const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
    const [createUserModalOpen, setCreateUserModalOpen] = useState<boolean>(false);
    const [listRole, setListRole] = useState<string>('participant');
    const [order, setOrder] = useState<boolean>(true);

    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useStateDebounced(search, 400);

    const [participantInfoModalOpen, setParticipantInfoModalOpen] = useState<boolean>(false);
    const [targetParticipantId, setTargetParticipantId] = useState<number>();
    const user = useSelector((state: RootState) => state.auth.user);
    const nextPage = useSelector((state: RootState) => state.admin.nextPage);
    const previousPage = useSelector((state: RootState) => state.admin.previousPage);

    const [userToDelete, setUserToDelete] = useState<IUser | null>();
    const userId = user?.id;
    const userName = user?.profile?.firstName;
    const userRole = user?.role;
    const notifyDeletedUser = () =>
        toast('User deleted successfully', {
            type: toast.TYPE.SUCCESS,
        });
    const notifyDeletedUserError = () =>
        toast('Could not delete user', {
            type: toast.TYPE.ERROR,
        });

    const dispatch = useDispatch();

    const usersList = useSelector((state: RootState) => state.admin.users);
    const onViewParticipant = (user: IUser) => {
        setTargetParticipantId(user.id);
        setParticipantInfoModalOpen(true);
    };
    const onCloseParticipantInfoModal = () => {
        dispatch(fetchUsers({ searchTerm: '', isAdmin: listRole === 'admin' }));
        setParticipantInfoModalOpen(false);
        setTargetParticipantId(undefined);
    };

    useEffect(() => {
        dispatch(fetchUsers({ searchTerm: '', isAdmin: listRole === 'admin' }));
    }, [dispatch, listRole]);

    useEffect(() => {
        dispatch(fetchUsers({ searchTerm: debouncedSearch, isAdmin: listRole === 'admin' }));
    }, [debouncedSearch, dispatch, listRole]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleRole = (event: React.ChangeEvent<HTMLInputElement>) => {
        setListRole(event.target.value);
        dispatch(fetchUsers({ searchTerm: '', isAdmin: event.target.value === 'admin' }));
    };

    if (!user) {
        return <Loader />;
    }

    const openDeleteModal = (id: number) => {
        const user = usersList?.filter((user) => user.id === id)[0];
        setUserToDelete(user);
    };

    const deleteUser = async (id: number) => {
        try {
            await HTTPDeleteRequest(`${BACKEND_ROUTES.USERS}/${id}`);
            dispatch(fetchUsers({ searchTerm: '', isAdmin: listRole === 'admin' }));
            notifyDeletedUser();
            setUserToDelete(null);
        } catch (error) {
            console.error(`Failed to delete user: ${error}`);
            notifyDeletedUserError();
        }
    };

    const orderByColumn = (id: string) => {
        dispatch(
            fetchUsers({
                searchTerm: debouncedSearch,
                orderBy: id,
                order,
                isAdmin: listRole === 'admin',
            }),
        );
        setOrder(!order);
    };

    return (
        <AdminPageContainer>
            <Helmet>
                <title>{t('admin.helmet')}</title>
            </Helmet>
            {profileModalOpen && <AdminProfileModal onCloseButton={setProfileModalOpen} />}
            {passwordModalOpen && <AdminPasswordModal onCloseButton={setPasswordModalOpen} />}
            {createUserModalOpen && <AdminCreateUserModal onCloseButton={setCreateUserModalOpen} />}
            {participantInfoModalOpen && targetParticipantId && (
                <AdminParticipantInfoModal
                    onCloseButton={onCloseParticipantInfoModal}
                    participantId={targetParticipantId}
                />
            )}
            {userToDelete && (
                <AdminDeleteUserModal
                    onCloseButton={() => setUserToDelete(null)}
                    user={userToDelete}
                    onDelete={deleteUser}
                />
            )}
            <AdminHeader
                userName={userName}
                role={userRole}
                profileModal={setProfileModalOpen}
                passwordModal={setPasswordModalOpen}
                createUserModal={setCreateUserModalOpen}
            />
            <AdminDownloadHeader />
            <ListHeader>
                <SearchInput
                    placeholder={`Id, ${t('admin.email')}, ${t('admin.firstName')}, ${t(
                        'admin.lastName',
                    )}`}
                    type="text"
                    onChange={handleSearch}
                />
                <TotalRecords>
                    {t('admin.total')}: {usersList?.length}
                </TotalRecords>
                <RadioContainer>
                    <label htmlFor="participant">
                        <RadioButton
                            type="radio"
                            id="participant"
                            name="role"
                            checked={listRole === 'participant'}
                            onChange={handleRole}
                            value="participant"
                        />
                        {t('admin.participant')}
                    </label>
                    <label htmlFor="admin">
                        <RadioButton
                            type="radio"
                            id="admin"
                            name="role"
                            checked={listRole === 'admin'}
                            onChange={handleRole}
                            value="admin"
                        />
                        {`${t('admin.admin')} / ${t('admin.coordinator')}`}
                    </label>
                </RadioContainer>
            </ListHeader>
            <div style={{ height: '100%', width: '100%' }}>
                <ToastContainer />
                {userId && userRole && usersList && usersList.length > 0 && (
                    <ListUsers
                        onClickColumn={orderByColumn}
                        currentUserRole={userRole}
                        currentUserId={userId}
                        users={usersList}
                        onDelete={openDeleteModal}
                        onView={onViewParticipant}
                    />
                )}
            </div>
            <div>
                <ButtonPage
                    active={Boolean(previousPage)}
                    onClick={() => dispatch(changePage(false))}
                >
                    Previous
                </ButtonPage>
                <ButtonPage active={Boolean(nextPage)} onClick={() => dispatch(changePage(true))}>
                    Next
                </ButtonPage>
            </div>
        </AdminPageContainer>
    );
};

export default Admin;
