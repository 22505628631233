import axios from 'axios';

export const HTTPGetRequest = <T extends unknown>(route: string) => {
    return axios.get<{ data: T; prev?: string; next?: string }>(route, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });
};

export const HTTPDeleteRequest = <T extends unknown>(route: string) => {
    return axios.delete<{ data: T }>(route, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
        },
    });
};
