import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../constants/routes';
import { RootState } from '../store';
import {
    validateEmail,
    validateFirstName,
    validateLastName,
    validateRole,
} from '../utils/validation';

export interface IError {
    message: string;
}

export interface IUserCreateState {
    user: any;
    error: IError | string | null;
    inProgress: boolean;
}

export const initialState: IUserCreateState = {
    user: null,
    error: null,
    inProgress: false,
};

export const clearCreateUser = createAsyncThunk(
    'create/user/clear',
    (params: null = null, { dispatch }) => {
        dispatch(clear());
    },
);
export const createUser = createAsyncThunk(
    'create/user',
    async (
        params: {
            firstName: string;
            lastName: string;
            email: string;
            role: string;
        },
        { dispatch, rejectWithValue },
    ) => {
        dispatch(setCreateUserInProgress(true));

        if (!validateFirstName(params.firstName)) {
            return rejectWithValue('Invalid first name provided to user creation');
        }

        if (!validateLastName(params.lastName)) {
            return rejectWithValue('Invalid last name provided to user creation');
        }

        if (!validateEmail(params.email)) {
            return rejectWithValue('Invalid email provided to user creation');
        }

        if (!validateRole(params.role)) {
            return rejectWithValue('Invalid role provided to user creation');
        }

        const token: string | null = localStorage.getItem('token');
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }
        try {
            const response = await axios.post(
                BACKEND_ROUTES.USERS,
                {
                    email: params.email,
                    role: params.role,
                    profile: {
                        firstName: params.firstName,
                        lastName: params.lastName,
                    },
                },
                config,
            );
            dispatch(setCreateUserAction(response.data.data));
        } catch (e) {
            const error = e as AxiosError<string>;
            let errorMessage: string | null = null;

            if (error.response?.status === 409) {
                errorMessage = 'User already exists';
            } else if (error.response?.status === 403) {
                errorMessage = 'Unauthorized';
            } else if (error.response?.status === 400) {
                errorMessage = 'Required fields missing';
            } else {
                errorMessage = 'An unexpected error as occurred.';
            }

            dispatch(setCreateUserErrorAction(errorMessage));
            return rejectWithValue(errorMessage);
        }
    },
);

export const slice = createSlice({
    name: 'createUser',
    initialState,
    reducers: {
        setCreateUserAction(state, action) {
            state.user = action.payload;
            state.error = null;
            state.inProgress = false;
        },
        setCreateUserErrorAction(state, action) {
            state.user = null;
            state.error = action.payload;
            state.inProgress = false;
        },
        setCreateUserInProgress(state, action) {
            state.user = null;
            state.error = null;
            state.inProgress = action.payload;
        },
        clear(state) {
            state.user = null;
            state.error = null;
            state.inProgress = false;
        },
    },
});

export const selectCreateUser = (state: RootState) => state.createUser.user;
export const selectCreateUserError = (state: RootState) => state.createUser.error;

export const {
    setCreateUserAction,
    setCreateUserInProgress,
    setCreateUserErrorAction,
    clear,
} = slice.actions;

export default slice.reducer;
