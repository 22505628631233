import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

import DropdownArrow from '../../assets/icons/dropdown_arrow.svg';

export const Error = styled.div`
    font-family: PoppinsRegular;
    color: ${colours.errorRed};
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FieldContainer = styled.div`
    margin-top: 38px;
`;

export const FieldTitle = styled.div`
    font-family: PoppinsMedium;
    color: ${colours.darkGrey};
    margin: 10px 0;

    @media ${sizes.XP} {
        font-size: 18px;
    }

    @media ${sizes.M} {
        font-size: 16px;
    }
`;

export const SearchResults = styled.div`
    display: grid;
    font-size: 11px;
    grid-gap: 5px;
    margin: 5px;
    width: 332px;
    max-height: 200px;
    overflow-y: scroll;
`;

export const SearchButtons = styled.button`
    border: 1px solid ${colours.grey};
    border-radius: 7px;
`;

export const Select = styled.select`
    width: 332px;
    font-family: PoppinsRegular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 48px;
    border-radius: 7px;
    border: solid 1px ${colours.mediumGrey};
    background-image: url(${DropdownArrow}) !important;
    background-position: 96% center;
`;

export const FieldHint = styled.div`
    font-family: PoppinsMedium;
    color: ${colours.darkGrey};
    width: 332px;

    @media ${sizes.XP} {
        font-size: smaller;
    }

    @media ${sizes.M} {
        font-size: smaller;
    }
`;

export const LangRadioGroup = styled.div`
    display: flex;
    flex-direction: row;
    width: 332px;
`;
