import React from 'react';
import { useTranslation } from 'react-i18next';

import { Background, Sky, Ground, IllustrationContainer, Illustration } from './styles';

import SchoolPeople from '../../assets/icons/school_people.svg';

export const SchoolAndPeople: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Background>
                <Sky />
                <Ground />
            </Background>
            <IllustrationContainer>
                <Illustration src={SchoolPeople} alt={t('homepage.illustration')} />
            </IllustrationContainer>
        </React.Fragment>
    );
};
