import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';

export const LoginField = styled.input`
    justify-self: center;
    font-family: PoppinsRegular;
    width: 100%;
    height: 48px;
    border: 1px solid ${colours.mediumGrey};
    border-radius: 7px;
`;

export const Error = styled.span`
    font-family: PoppinsRegular;
    color: ${colours.errorRed};
`;

export const Label = styled.label`
    display: block;
    text-align: left;
`;

export const Fields = styled.div`
    justify-self: center;

    @media ${sizes.XP} {
        width: 100%;
        padding: 40px 20px;
    }

    @media ${sizes.M} {
        width: 70%;
    }
`;
export const WelcomeTextContainer = styled.div`
    @media ${sizes.XP} {
        margin: 10px 20px 40px;
    }

    @media ${sizes.M} {
        margin: 50px;
    }
`;

export const ResetLink = styled(Link)`
    font-family: PoppinsMedium;
    color: ${colours.purple};
    text-decoration: none;
`;

export const Container = styled.div`
    display: grid;
    place-items: center;

    @media ${sizes.XP} {
        grid-template-rows: 15% auto 5%;
    }
    @media ${sizes.G} {
        grid-template-rows: 15% auto 5%;
    }
`;
