import React from 'react';

import { OptionContainer, OptionLabel } from '../CheckboxQuestion/styles';
import { CustomRadio, HiddenRadio } from '../RadioQuestion/styles';

export interface IProps {
    checked: boolean;
    handleChange: () => void;
    name: string;
    id: string;
    value: string;
    text: string;
}

export const RadioOption: React.FC<IProps> = ({ checked, handleChange, name, id, value, text }) => {
    return (
        <OptionContainer>
            <CustomRadio checked={checked} onClick={handleChange} />
            <HiddenRadio type="radio" name={name} id={id} value={value} onChange={handleChange} />
            <OptionLabel htmlFor={name}>{text}</OptionLabel>
        </OptionContainer>
    );
};

export default RadioOption;
