import React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

// Components
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

import Family from '../../assets/icons/family.svg';

// Styles
import { CenterContainer, Text, Note, Link } from './styles';
import { PageContainer, Title } from '../../styles/common';
import { Image } from '../thank_you/styles';

// Utils
import { ROUTES } from '../../constants/routes';

const SignupConfirmation: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <PageContainer>
            <Helmet>
                <title>{t(`signupConfirmation.helmet`)}</title>
            </Helmet>
            <Header />
            <CenterContainer>
                <Title>{t(`signupConfirmation.title`)}</Title>
                <Image src={Family} alt={t('thankyou.illustration')} />
                <Text>{t(`signupConfirmation.text`)}</Text>
                <Note>
                    <Trans i18nKey="signupConfirmation.note">
                        If you haven't received the login information, please check your spam
                        folder. If more than 24h has passed, please contact us at
                        <Link href="mailto:info@encorestudy.ca" target="_blank">
                            encorestudy.ca
                        </Link>
                    </Trans>
                </Note>
            </CenterContainer>
            <Footer hasAnswer={true} goTo={ROUTES.LOGIN} />
        </PageContainer>
    );
};

export default SignupConfirmation;
