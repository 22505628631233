import React from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/Button';

import SchoolPeople from '../../assets/icons/school_people.svg';

import HomeLayout from '../../layouts/home';

import { Title, Subtitle, Subtitle2, IllustrationMobile, Login } from './styles';

const Home: React.FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('homepage.home')}</title>
            </Helmet>
            <HomeLayout footerText={t('homepage.footer')} showAdmin>
                <Title>{t('homepage.title')}</Title>
                <Subtitle>{t('homepage.subtitle1')}</Subtitle>
                <IllustrationMobile src={SchoolPeople} alt={t('homepage.illustration')} />
                <Subtitle2>{t('homepage.subtitle2')}</Subtitle2>
                <NavLink to="/consent" exact>
                    <Button label={t('homepage.button')} />
                </NavLink>
                <Login to="/login" exact>
                    {t('homepage.login')}
                </Login>
            </HomeLayout>
        </>
    );
};

export default Home;
