import styled from 'styled-components';

import InputMask from 'react-input-mask';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

export const DateContainer = styled.div`
    text-align: left;
`;

export const Datehint = styled.div`
    font-family: PoppinsMedium;
    color: ${colours.darkGrey};

    @media ${sizes.XP} {
        font-size: 18px;
    }

    @media ${sizes.M} {
        font-size: 16px;
    }
`;

export const Datefield = styled(InputMask)`
    font-family: PoppinsRegular;
    width: 332px;
    height: 48px;
    border: 1px solid ${colours.mediumGrey};
    border-radius: 7px;
`;

export const DateError = styled.div`
    font-family: PoppinsRegular;
    color: ${colours.errorRed};
    font-size: 14px;
    margin-top: 10px;
`;
