export const colours = {
    primary: '#3a529c',
    secondary: '#1ca9f0',
    white: '#ffffff',
    black: '#000000',
    grey: '#ededf7',
    offWhite: '#f4f5f9',
    navy: '#495576',
    // Encore Project Specifics
    purple: '#612989',
    purpleHover: '#7B599D',
    pink: '#D91C5C',
    lightGrey: '#F6F8FA',
    mediumGrey: '#A3ADB6',
    darkGrey: '#7A8289',
    lightBeige: '#E3E2E3',
    dropShadow: 'rgba(0, 0, 0, 0.07)',
    errorRed: '#E10101',
    lightBlue: '#b2d0ed',
    greenGrass: '#77C588',
    modalGrey: 'rgba(0, 0, 0, 0.6)',
};
