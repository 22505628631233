const { REACT_APP_BASE_URL, REACT_APP_API_PREFIX } = process.env;
const API_URL = `${REACT_APP_BASE_URL}/${REACT_APP_API_PREFIX}`;

export const ROUTES = {
    LOGIN: '/login',
    SURVEY: '/survey',
    ADMIN: '/admin',
    ADMIN_LOGIN: '/admin-login',
    HOME: '/',
    CONSENT: '/consent',
    PROFILE: '/profile',
    THANK_YOU: '/thankyou',
    RESET_PASSWORD: '/reset-password',
    SIGNUP_CONFIRMATION: '/signup-confirmation',
};

export const BACKEND_ROUTES = {
    BASE_URL: `${REACT_APP_BASE_URL}`,
    LOGIN: `${API_URL}/auth/login`,
    WHOAMI: `${API_URL}/auth/whoami`,
    SURVEY: `${API_URL}/survey/json`,
    SURVEY_DB: `${API_URL}/survey`,
    ANSWER: `${API_URL}/survey/answer`,
    SIGNUP: `${API_URL}/users/signup`,
    RESET_PASSWORD: `${API_URL}/users/reset-password`,
    SEND_RESET_PASSWORD: `${API_URL}/users/send-password-reset`,
    UPDATE_PROFILE: `${API_URL}/users/update-user`,
    USERS: `${API_URL}/users`,
    CHECK_PASSWORD: `${API_URL}/auth/check-password`,
    PREVIOUS_QUESTION: `${API_URL}/survey/previous`,
    DOWNLOAD_USER_DATA: `${API_URL}/users/download-csv`,
    DOWNLOAD_SURVEY_DATA: `${API_URL}/survey/download-csv`,
};
