/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { CheckboxQuestion } from '../CheckboxQuestion';
import { DateQuestion } from '../DateQuestion';
import { DropdownQuestion } from '../DropdownQuestion';
import { MultiRadioQuestion } from '../MultiRadioQuestion';
import { NumberQuestion } from '../NumberQuestion';
import { RadioQuestion } from '../RadioQuestion';
import { SelectboxQuestion } from '../SelectboxQuestion';
import { TextfieldQuestion } from '../TextfieldQuestion';
import Loader from '../Loader';

import { MultitypeContainer, OrderTitle, QuestionnaireContainer } from './styles';

import { QuestionOptionType, QuestionType } from '../../slices/surveySlice';
import { GroupedQuestion } from '../GroupedQuestion';
import { QuestionTypeId } from '../../constants/questionTypes';

export interface IProps {
    question: number;
    text: string;
    options?: QuestionOptionType[];
    conditional?: QuestionType;
}

export const MultitypeQuestion: React.FunctionComponent<IProps> = ({
    question,
    options,
    text,
    conditional,
}) => {
    const { t } = useTranslation();
    const renderElement = (element: QuestionOptionType, index: number, optionIndex: number) => {
        switch (element.questionTypeId) {
            case QuestionTypeId.checkbox:
                return (
                    <CheckboxQuestion
                        question={question}
                        index={index}
                        options={element.question_child as QuestionOptionType[]}
                        text={element.text}
                        key={element.id}
                    />
                );
            case QuestionTypeId.radio:
                return (
                    <RadioQuestion
                        question={question}
                        key={element.id}
                        index={Number(index.toString() + optionIndex.toString())}
                        text={element.text}
                        options={element.question_child as QuestionOptionType[]}
                    />
                );
            case QuestionTypeId.text:
                return (
                    <TextfieldQuestion
                        question={question}
                        index={index}
                        value={element.answer.filter((answer: any) => answer.order === index)}
                        questionOptionId={element.id}
                        text={element.text}
                        key={element.id}
                        options={element.question_child as QuestionOptionType[]}
                    />
                );
            case QuestionTypeId.date:
                return (
                    <DateQuestion
                        question={question}
                        index={index}
                        questionOptionId={element.id}
                        value={element.answer.filter((answer: any) => answer.order === index)}
                        text={element.text}
                        key={element.id}
                        minYear={2020}
                        maxYear={2021}
                    />
                );
            case QuestionTypeId.dropdown:
                return (
                    <DropdownQuestion
                        question={element.id}
                        options={element.question_child as QuestionOptionType[]}
                        text={element.text}
                        key={element.id}
                    />
                );
            case QuestionTypeId.number:
                return (
                    <NumberQuestion
                        question={question}
                        questionOptionId={element.id}
                        text={element.text}
                        index={index}
                        key={element.id}
                        value={
                            (
                                element.answer.filter((answer: any) => answer.order === index)[0] ??
                                {}
                            ).answer
                        }
                        min={element.min ?? 1}
                        max={element.max ?? 100}
                    />
                );
            case QuestionTypeId.groupedtype:
                return (
                    <GroupedQuestion
                        question={question}
                        options={element.question_child as QuestionOptionType[]}
                        value={element.answer.filter((answer: any) => answer.order === index) ?? {}}
                        index={index}
                        questionOptionId={element.id}
                        text={element.text}
                        key={element.id}
                    />
                );
            case QuestionTypeId.select:
                return <SelectboxQuestion question={element as any} />;
            case QuestionTypeId.multiradio:
                return (
                    <MultiRadioQuestion
                        question={element.id}
                        options={element.question_child as QuestionOptionType[]}
                        text={element.text}
                        key={element.id}
                    />
                );
            default:
                return (
                    <div key={element.id}>
                        Question {element.id}: Component {element.questionTypeId} to be deployed
                        soon
                    </div>
                );
        }
    };

    if (!options) {
        return <Loader />;
    }
    // Number of questionnaires to render according to selected number on previous question. This value will come from a prop.
    const tests = ((conditional ?? {}).answer[0] ?? {}).answer ?? 0;

    return (
        <MultitypeContainer>
            {[...Array(Number(tests))].map((e, i) => {
                const index = i + 1;
                return (
                    <QuestionnaireContainer>
                        <OrderTitle>{t(`survey.${text}Label`, { index })}</OrderTitle>
                        {options.map((option, optionIndex) => (
                            <>{renderElement(option, i + 1, optionIndex + 1)}</>
                        ))}
                    </QuestionnaireContainer>
                );
            })}
        </MultitypeContainer>
    );
};
