import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

export const Container = styled.div`
    height: 100%;
    display: grid;
    @media ${sizes.XP} {
        grid-template-columns: 1fr;
    }

    @media ${sizes.G} {
        grid-template-columns: 55% 45%;
    }
`;

export const Footer = styled.p`
    font-family: PoppinsRegular;
    text-align: center;
    font-size: 12px;
    margin: 20px;
`;

export const LeftSide = styled.div`
    position: relative;
    height: 100%;

    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.G} {
        display: revert;
    }
`;

export const RightSide = styled.div`
    display: grid;
    place-items: center;
    grid-template-rows: 77px 25% auto;
`;

export const LogoContainer = styled.div`
    width: 100%;
    display: grid;
    place-items: center;
`;

export const LanguageSwitchMobileContainer = styled.div`
    height: 100%;
`;

export const LanguageSwitchMobile = styled.button`
    height: 100%;
    width: 100%;
    background-color: ${colours.purple};
    color: white;
    border: none;

    :hover {
        background-color: ${colours.purpleHover};
    }

    @media ${sizes.XP} {
        display: revert;
    }

    @media ${sizes.G} {
        display: none;
    }
`;

export const CenterContainer = styled.div`
    display: grid;
    justify-items: center;
    height: 100%;
    width: 90%;
`;

export const ContactUs = styled.div`
    text-align: center;

    @media ${sizes.XP} {
        font-size: 12px;
        margin: 0 15px;
    }

    @media ${sizes.G} {
        font-size: 14px;
        margin: 0 35px;
    }
`;

export const Login = styled(NavLink)`
    display: grid;
    place-items: center;
    text-decoration: none;
    font-family: 'NunitoBold';
    margin: 0 20px;
`;
