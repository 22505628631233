/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
    LoginField,
    Error,
    Label,
    Fields,
    WelcomeTextContainer,
    ResetLink,
    Container,
} from './styles';

import { Button } from '../../components/Button';

import HomeLayout from '../../layouts/home';

import { BACKEND_ROUTES } from '../../constants/routes';
import { Title } from '../../styles/common';

const Home: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<boolean>(false);

    const history = useHistory();

    const { t } = useTranslation();

    const login = async () => {
        try {
            const { data } = await axios.post<{ data: any }>(BACKEND_ROUTES.LOGIN, {
                email: username,
                password,
            });

            if (data.data.token !== '') {
                setError(false);
                localStorage.setItem('token', data.data.token);
                history.push('/survey');
            }
        } catch (error) {
            localStorage.removeItem('token');
            setError(true);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('login.title')}</title>
            </Helmet>
            <HomeLayout showAdmin>
                <Container>
                    <WelcomeTextContainer>
                        <Title>Login</Title>
                        <p>{t('login.welcomeText')}</p>
                        <Trans i18nKey="login.lostPassword">
                            If you have lost your password, you can reset it by clicking
                            <ResetLink to="/reset-password">here</ResetLink>.
                        </Trans>
                    </WelcomeTextContainer>
                    <Fields>
                        <Label htmlFor="login">{t('login.username')}</Label>
                        <LoginField
                            type="text"
                            name="login"
                            id="login"
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                        />
                    </Fields>
                    <Fields>
                        <Label htmlFor="password">{t('login.password')}</Label>
                        <LoginField
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        {error && <Error>{t('login.authError')}</Error>}
                    </Fields>
                    <Button label={t('login.login')} onClick={login} justifySelf="center" />
                </Container>
            </HomeLayout>
        </>
    );
};

export default Home;
