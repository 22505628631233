import React from 'react';

import { useTranslation } from 'react-i18next';

import { IUser } from '../../slices/authSlice';
import {
    BinItem,
    Header,
    Row,
    UsersContainer,
    UserId,
    FirstName,
    LastName,
    Email,
    Action,
} from './styles';

import { UserStatus } from '../../styles/common';

import { colours } from '../../constants/colours';
import ROLES from '../../constants/roles';

type onDeleteFunction = (id: number) => void;
type onClickColumnFunction = (column: string) => void;

export interface IProps {
    currentUserId: number;
    currentUserRole: string;
    users: IUser[];
    onView: (user: IUser) => void;
    onDelete: onDeleteFunction;
    onClickColumn: onClickColumnFunction;
}
const ListUsers: React.FunctionComponent<IProps> = ({
    currentUserId,
    currentUserRole,
    users,
    onView,
    onClickColumn,
    onDelete,
}) => {
    const { t } = useTranslation();

    return (
        <UsersContainer>
            <Header>
                <UserId onClick={() => onClickColumn('id')}>{t('admin.userId')}</UserId>
                <UserStatus onClick={() => onClickColumn('status')} hideIcon={true}>
                    {t('admin.status')}
                </UserStatus>
                <FirstName onClick={() => onClickColumn('firstName')}>
                    {t('admin.firstName')}
                </FirstName>
                <LastName onClick={() => onClickColumn('lastName')}>{t('admin.lastName')}</LastName>
                <Email onClick={() => onClickColumn('email')}>{t('admin.email')}</Email>
                <Action color={colours.black}>{t('admin.actions')}</Action>
                <BinItem hideIcon={true}></BinItem>
            </Header>
            {users.map((user) => {
                return (
                    <Row key={user.id}>
                        <UserId key={`id-${user.id}`}>{`${user.id}`}</UserId>
                        <UserStatus status={user.status} key={`status-${user.status}`} />
                        <FirstName key={`fn-${user.id}`}>{`${user.profile.firstName}`}</FirstName>
                        <LastName key={`ln-${user.id}`}>{`${user.profile.lastName}`}</LastName>
                        <Email key={`email-${user.id}`}>{`${user.email}`}</Email>
                        <Action
                            pointer={true}
                            key={`action-${user.id}`}
                            onClick={() => {
                                onView(user);
                            }}
                        >
                            {t('admin.view')}
                        </Action>
                        <BinItem
                            hideIcon={currentUserRole !== ROLES.ADMIN || currentUserId === user.id}
                            key={`bin-${user.id}`}
                            onClick={() => onDelete(user.id)}
                        />
                    </Row>
                );
            })}
        </UsersContainer>
    );
};

export default ListUsers;
