import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../constants/routes';
import { RootState } from '../store';
import { getParticipantInfo, IParticipantInfo } from './getParticipantInfoSlice';

export interface IError {
    message: string;
}

export interface IParticipantUpdateState {
    success: boolean | null;
    error: IError | string | null;
    inProgress: boolean;
}

export const initialState: IParticipantUpdateState = {
    success: null,
    error: null,
    inProgress: false,
};

export const clearUpdateParticipantInfo = createAsyncThunk(
    'update/participantInfo/clear',
    (_, { dispatch }) => {
        dispatch(clear());
    },
);

export const updateParticipantInfo = createAsyncThunk(
    'update/participantInfo',
    async (participant: IParticipantInfo, { dispatch, rejectWithValue }) => {
        dispatch(setUpdateParticipantInfoInProgress(true));

        const token: string | null = localStorage.getItem('token');
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }
        try {
            await axios.put(
                `${BACKEND_ROUTES.USERS}/${participant.id}`,
                {
                    ...participant,
                    profile: { ...participant.profile },
                    survey: undefined,
                },
                config,
            );

            dispatch(setUpdateParticipantInfoActionSuccess(true));
            dispatch(getParticipantInfo(participant.id));
        } catch (e) {
            const error = e as AxiosError<string>;
            let errorMessage: string | null = null;

            if (error.response?.status === 404) {
                errorMessage = "Participant doesn't exist";
            } else if (error.response?.status === 401) {
                errorMessage = 'Unauthorized';
            } else if (error.response?.status === 403) {
                errorMessage = 'Forbidden';
            } else {
                errorMessage = 'An unexpected error as occurred.';
            }

            dispatch(setUpdateParticipantInfoErrorAction(errorMessage));
            return rejectWithValue(errorMessage);
        }
    },
);

export const slice = createSlice({
    name: 'updateParticipantInfo',
    initialState,
    reducers: {
        setUpdateParticipantInfoActionSuccess(state, action) {
            state.success = action.payload;
            state.error = null;
            state.inProgress = false;
        },
        setUpdateParticipantInfoErrorAction(state, action) {
            state.success = false;
            state.error = action.payload;
            state.inProgress = false;
        },
        setUpdateParticipantInfoInProgress(state, action) {
            state.success = null;
            state.error = null;
            state.inProgress = action.payload;
        },
        clear(state) {
            state.success = null;
            state.error = null;
            state.inProgress = false;
        },
    },
});

export const selectUpdateParticipantInfoSuccess = (state: RootState) =>
    state.updateParticipantInfo.success;
export const selectUpdateParticipantInfoError = (state: RootState) =>
    state.updateParticipantInfo.error;
export const selectUpdateParticipantInfoInProgress = (state: RootState) =>
    state.updateParticipantInfo.inProgress;

export const {
    setUpdateParticipantInfoInProgress,
    setUpdateParticipantInfoErrorAction,
    setUpdateParticipantInfoActionSuccess,
    clear,
} = slice.actions;

export default slice.reducer;
