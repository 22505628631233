import React from 'react';

import { StyledButton } from '../../styles/common';

export interface IProps {
    label: string;
    active?: boolean;
    onClick?: () => void;
    justifySelf?: string;
}

export const Button: React.FC<IProps> = ({ label, active, onClick, justifySelf }) => {
    return (
        <StyledButton onClick={onClick} active={active ?? true} justifySelf={justifySelf}>
            {label}
        </StyledButton>
    );
};
