import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SuccessIcon from '../../assets/icons/success.svg';
import CloseIcon from '../../assets/icons/close_icon.svg';

import { FieldsGroup, FieldsGroupContainer } from './styles';
import {
    Modal,
    ModalContainer,
    Cancel,
    Send,
    CloseButton,
    Title,
    Label,
    Field,
    FieldContainer,
    ButtonsContainer,
} from '../../styles/modal';
import {
    IParticipantInfo,
    getParticipantInfo,
    selectGetParticipantInfo,
    clearGetParticipantInfo,
} from '../../slices/getParticipantInfoSlice';
import {
    selectUpdateParticipantInfoInProgress,
    selectUpdateParticipantInfoSuccess,
    clearUpdateParticipantInfo,
    updateParticipantInfo,
} from '../../slices/updateParticipantInfoSlice';
import { FieldError, UserStatus, RadioGroup } from '../../styles/common';
import {
    validateEmail,
    validateFirstName,
    validateLastName,
    validateCity,
    validateFirstAddressLine,
    validatePostalCode,
    validateProvince,
    validateSecondAddressLine,
    validatePhone,
} from '../../utils/validation';
import { RadioOption } from '../RadioOption';
import Loader from '../Loader';

export interface IProps {
    participantId: number;
    onCloseButton: () => void;
}

export const AdminParticipantInfoModal: React.FunctionComponent<IProps> = ({
    participantId,
    onCloseButton,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getParticipantInfo(participantId));
    }, [dispatch, participantId]);

    const [editMode, setEditMode] = useState<boolean>();
    const [participant, setParticipant] = useState<IParticipantInfo>();
    const [invalidEmail, setEmailInvalid] = useState<boolean>(false);
    const [invalidFirstName, setFirstNameInvalid] = useState<boolean>(false);
    const [invalidLastName, setLastNameInvalid] = useState<boolean>(false);
    const [invalidPostalCode, setPostalCodeInvalid] = useState<boolean>(false);
    const [invalidFirstAddressLine, setFirstAddressLineInvalid] = useState<boolean>(false);
    const [invalidSecondAddressLine, setSecondAddressLineInvalid] = useState<boolean>(false);
    const [invalidCity, setCityInvalid] = useState<boolean>(false);
    const [invalidProvince, setProvinceInvalid] = useState<boolean>(false);
    const [invalidPhone, setPhoneInvalid] = useState<boolean>(false);

    const sourceParticipant: IParticipantInfo | null = useSelector(selectGetParticipantInfo);
    const updateInProgress: boolean = useSelector(selectUpdateParticipantInfoInProgress);
    const success: boolean | null = useSelector(selectUpdateParticipantInfoSuccess);

    const isEmailValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateEmail(participant.email);
        setEmailInvalid(!isValid);
        return isValid;
    };

    const isFirstNameValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateFirstName(participant.profile.firstName);
        setFirstNameInvalid(!isValid);
        return isValid;
    };
    const isLastNameValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateLastName(participant.profile.lastName);
        setLastNameInvalid(!isValid);
        return isValid;
    };
    const isPostalCodeValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validatePostalCode(participant.profile.postalCode);
        setPostalCodeInvalid(!isValid);
        return isValid;
    };
    const isFirstAddressLineValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateFirstAddressLine(participant.profile.firstAddressLine);
        setFirstAddressLineInvalid(!isValid);
        return isValid;
    };
    const isSecondAddressLineValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateSecondAddressLine(participant.profile.secondAddressLine);
        setSecondAddressLineInvalid(!isValid);
        return isValid;
    };
    const isCityValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateCity(participant.profile.city);
        setCityInvalid(!isValid);
        return isValid;
    };
    const isProvinceValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validateProvince(participant.profile.province);
        setProvinceInvalid(!isValid);
        return isValid;
    };
    const isPhoneValid = (): boolean => {
        if (typeof participant === 'undefined') {
            return false;
        }

        const isValid = validatePhone(participant.profile.phone);
        setPhoneInvalid(!isValid);
        return isValid;
    };

    const onEditMode = (newEditMode: boolean) => {
        if (!sourceParticipant) {
            return;
        }
        if (newEditMode) {
            setEditMode(true);
            setParticipantAsCopy();
        } else {
            setEditMode(false);
            setParticipant(sourceParticipant);
            dispatch(clearUpdateParticipantInfo());
        }
    };

    // we'll be editing so we need a copy to play with
    const setParticipantAsCopy = () => {
        if (!sourceParticipant) {
            return;
        }
        setParticipant({
            ...sourceParticipant,
            profile: {
                ...sourceParticipant.profile,
            },
        });
    };

    const onChangeValue = () => {
        dispatch(clearUpdateParticipantInfo());
    };

    const modifyLocalParticipant = (candidateParticipant: IParticipantInfo) => {
        onChangeValue();
        setParticipant(candidateParticipant);
    };

    const updateLanguage = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                language: value,
            },
        });
    };

    const updateEmail = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            email: value,
            profile: {
                ...participant.profile,
            },
        });
    };

    const updateFirstName = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                firstName: value,
            },
        });
    };

    const updateLastName = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                lastName: value,
            },
        });
    };

    const updatePostalCode = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                postalCode: value,
            },
        });
    };

    const updateFirstAddressLine = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                firstAddressLine: value,
            },
        });
    };

    const updateSecondAddressLine = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                secondAddressLine: value,
            },
        });
    };

    const updateCity = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                city: value,
            },
        });
    };

    const updateProvince = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                province: value,
            },
        });
    };

    const updatePhone = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                phone: value,
            },
        });
    };

    const updateTestKitSent = (value: boolean) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                testKitSent: value,
            },
        });
    };

    const updateSampleReceivedAtLab = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                sampleReceivedAtLab: value,
            },
        });
    };

    const updateLabStatusResult = (value: string) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            profile: {
                ...participant.profile,
                labStatusResult: value,
            },
        });
    };

    const updateStatus = (status: number) => {
        if (!participant) {
            return;
        }
        modifyLocalParticipant({
            ...participant,
            status,
        });
    };

    const isFormValid = () => {
        return (
            isEmailValid() &&
            isFirstNameValid() &&
            isLastNameValid() &&
            isPostalCodeValid() &&
            isFirstAddressLineValid() &&
            isSecondAddressLineValid() &&
            isCityValid() &&
            isProvinceValid() &&
            isPhoneValid()
        );
    };

    const onUpdateForm = () => {
        if (updateInProgress || !participant || !isFormValid()) {
            return;
        }

        dispatch(updateParticipantInfo(participant));
    };

    const onClose = () => {
        dispatch(clearUpdateParticipantInfo());
        dispatch(clearGetParticipantInfo());
        onCloseButton();
    };

    // means we just opened the modal, we need to set the edit mode
    if (typeof editMode === 'undefined') {
        onEditMode(false);
    }

    const getCloseButton = () => (
        <CloseButton onClick={onClose}>
            <img src={CloseIcon} alt="close" />
        </CloseButton>
    );

    const getEnrollmentDateField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.enrollmentDate')}</Label>
            <Field
                type="text"
                value={new Date(String(participant?.createdAt)).toDateString()}
                disabled={true}
            ></Field>
        </FieldContainer>
    );

    const getPlaceOfWorkField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.workplace')}</Label>
            <Field
                type="text"
                value={
                    participant?.survey?.workplace && participant.survey.workplace.length
                        ? participant.survey.workplace
                        : 'N/A'
                }
                disabled={true}
            ></Field>
        </FieldContainer>
    );

    const getCompletionDateField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.completionDate')}</Label>
            <Field
                type="text"
                value={
                    participant?.survey?.completedAt && participant.survey.completedAt.length
                        ? new Date(String(participant.survey.completedAt)).toString()
                        : 'N/A'
                }
                disabled={true}
            ></Field>
        </FieldContainer>
    );

    const getStatusField = (): JSX.Element => {
        const statusOptions = [
            [t('admin.participantInfoModal.enable'), 1],
            [t('admin.participantInfoModal.disable'), 0],
        ];
        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.status')}</Label>
                {!editMode ? (
                    <UserStatus
                        status={participant?.status}
                        key={`status-${participant?.status}`}
                    />
                ) : (
                    <RadioGroup>
                        {statusOptions.map(([text, value]) => {
                            const stringValue = String(value);
                            return (
                                <RadioOption
                                    key={stringValue}
                                    handleChange={() => updateStatus(Number(value))}
                                    name={stringValue}
                                    id={stringValue}
                                    value={stringValue}
                                    text={String(text)}
                                    checked={value === participant?.status}
                                />
                            );
                        })}
                    </RadioGroup>
                )}
            </FieldContainer>
        );
    };

    const getLanguageField = (): JSX.Element => {
        const languageOptions = [
            [t('admin.participantInfoModal.english'), 'en'],
            [t('admin.participantInfoModal.french'), 'fr'],
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.language')}</Label>
                {editMode ? (
                    <RadioGroup>
                        {languageOptions.map(([text, value]) => {
                            return (
                                <RadioOption
                                    key={value}
                                    handleChange={() => updateLanguage(value)}
                                    name={String(value)}
                                    id={value}
                                    value={value}
                                    text={String(text)}
                                    checked={value === participant?.profile.language}
                                />
                            );
                        })}
                    </RadioGroup>
                ) : (
                    (languageOptions.find(
                        (option) => option[1] === participant?.profile?.language,
                    ) ?? languageOptions[1])[0]
                )}
            </FieldContainer>
        );
    };

    const getEmailField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.email')}</Label>
            <Field
                type="text"
                value={participant?.email}
                disabled={!editMode}
                onChange={(event) => {
                    updateEmail(event.target.value);
                }}
                onBlur={isEmailValid}
            ></Field>
            {invalidEmail && (
                <FieldError>{t('admin.participantInfoModal.invalidEmail')}</FieldError>
            )}
        </FieldContainer>
    );

    const getFirstNameField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.firstName')}</Label>
            <Field
                type="text"
                value={participant?.profile.firstName}
                disabled={!editMode}
                onChange={(event) => {
                    updateFirstName(event.target.value);
                }}
                onBlur={isFirstNameValid}
            ></Field>
            {invalidFirstName && (
                <FieldError>{t('admin.participantInfoModal.invalidFirstName')}</FieldError>
            )}
        </FieldContainer>
    );

    const getLastNameField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.lastName')}</Label>
            <Field
                type="text"
                value={participant?.profile.lastName}
                disabled={!editMode}
                onChange={(event) => {
                    updateLastName(event.target.value);
                }}
                onBlur={isLastNameValid}
            ></Field>
            {invalidLastName && (
                <FieldError>{t('admin.participantInfoModal.invalidLastName')}</FieldError>
            )}
        </FieldContainer>
    );

    const getPostalCodeField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.postalCode')}</Label>
            <Field
                type="text"
                value={participant?.profile.postalCode}
                disabled={!editMode}
                onChange={(event) => {
                    updatePostalCode(event.target.value);
                }}
                onBlur={isPostalCodeValid}
            ></Field>
            {invalidPostalCode && (
                <FieldError>{t('admin.participantInfoModal.invalidPostalCode')}</FieldError>
            )}
        </FieldContainer>
    );

    const getFirstAddressLineField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.firstAddressLine')}</Label>
            <Field
                type="text"
                value={participant?.profile.firstAddressLine}
                disabled={!editMode}
                onChange={(event) => {
                    updateFirstAddressLine(event.target.value);
                }}
                onBlur={isFirstAddressLineValid}
            ></Field>
            {invalidFirstAddressLine && (
                <FieldError>{t('admin.participantInfoModal.invalidFirstAddressLine')}</FieldError>
            )}
        </FieldContainer>
    );

    const getSecondAddressLineField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.secondAddressLine')}</Label>
            <Field
                type="text"
                value={participant?.profile.secondAddressLine}
                disabled={!editMode}
                onChange={(event) => {
                    updateSecondAddressLine(event.target.value);
                }}
                onBlur={isSecondAddressLineValid}
            ></Field>
            {invalidSecondAddressLine && (
                <FieldError>{t('admin.participantInfoModal.invalidSecondAddressLine')}</FieldError>
            )}
        </FieldContainer>
    );

    const getCityField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.city')}</Label>
            <Field
                type="text"
                value={participant?.profile.city}
                disabled={!editMode}
                onChange={(event) => {
                    updateCity(event.target.value);
                }}
                onBlur={isCityValid}
            ></Field>
            {invalidCity && <FieldError>{t('admin.participantInfoModal.invalidCity')}</FieldError>}
        </FieldContainer>
    );

    const getProvinceField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.province')}</Label>
            <Field
                type="text"
                value={participant?.profile.province}
                disabled={!editMode}
                onChange={(event) => {
                    updateProvince(event.target.value);
                }}
                onBlur={isProvinceValid}
            ></Field>
            {invalidProvince && (
                <FieldError>{t('admin.participantInfoModal.invalidProvince')}</FieldError>
            )}
        </FieldContainer>
    );

    const getPhoneField = (): JSX.Element => (
        <FieldContainer>
            <Label>{t('admin.participantInfoModal.phone')}</Label>
            <Field
                type="text"
                value={participant?.profile.phone}
                disabled={!editMode}
                onChange={(event) => {
                    updatePhone(event.target.value);
                }}
                onBlur={isPhoneValid}
            ></Field>
            {invalidPhone && (
                <FieldError>{t('admin.participantInfoModal.invalidPhone')}</FieldError>
            )}
        </FieldContainer>
    );

    const getTestKitSentField = (): JSX.Element => {
        const testKitSentOptions = [
            [t('admin.participantInfoModal.yes'), true],
            [t('admin.participantInfoModal.no'), false],
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.testKitSent')}</Label>
                {editMode ? (
                    <RadioGroup>
                        {testKitSentOptions.map(([text, value]) => {
                            const stringValue = String(value);
                            return (
                                <RadioOption
                                    key={String(value)}
                                    handleChange={() => updateTestKitSent(Boolean(value))}
                                    name={String(value)}
                                    id={stringValue}
                                    value={stringValue}
                                    text={String(text)}
                                    checked={value === participant?.profile.testKitSent}
                                />
                            );
                        })}
                    </RadioGroup>
                ) : (
                    (testKitSentOptions.find(
                        (option) => option[1] === participant?.profile.testKitSent,
                    ) ?? testKitSentOptions[1])[0]
                )}
            </FieldContainer>
        );
    };

    const getSampleReceivedAtLabField = (): JSX.Element => {
        const sampleReceivedAtLabOptions = [
            [t('admin.participantInfoModal.yes'), 'yes'],
            [t('admin.participantInfoModal.no'), 'no'],
            [t('admin.participantInfoModal.enRoute'), 'en route'],
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.sampleReceivedAtLab')}</Label>
                {editMode ? (
                    <RadioGroup>
                        {sampleReceivedAtLabOptions.map(([text, value]) => {
                            return (
                                <RadioOption
                                    key={value}
                                    handleChange={() => updateSampleReceivedAtLab(value)}
                                    name={value}
                                    id={value}
                                    value={value}
                                    text={text}
                                    checked={value === participant?.profile.sampleReceivedAtLab}
                                />
                            );
                        })}
                    </RadioGroup>
                ) : (
                    (sampleReceivedAtLabOptions.find(
                        (option) => option[1] === participant?.profile.sampleReceivedAtLab,
                    ) ?? sampleReceivedAtLabOptions[1])[0]
                )}
            </FieldContainer>
        );
    };

    const getLabStatusResultField = (): JSX.Element => {
        const labStatusResultOptions = [
            [t('admin.participantInfoModal.positive'), 'positive'],
            [t('admin.participantInfoModal.negative'), 'negative'],
            [t('admin.participantInfoModal.notAvailable'), 'n/a'],
        ];

        return (
            <FieldContainer>
                <Label>{t('admin.participantInfoModal.labStatusResult')}</Label>
                {editMode ? (
                    <RadioGroup>
                        {labStatusResultOptions.map(([text, value]) => {
                            return (
                                <RadioOption
                                    key={value}
                                    handleChange={() => updateLabStatusResult(value)}
                                    name={value}
                                    id={value}
                                    value={value}
                                    text={text}
                                    checked={value === participant?.profile.labStatusResult}
                                />
                            );
                        })}
                    </RadioGroup>
                ) : (
                    (labStatusResultOptions.find(
                        (option) => option[1] === participant?.profile.labStatusResult,
                    ) ?? labStatusResultOptions[2])[0]
                )}
            </FieldContainer>
        );
    };

    const getButtons = (): JSX.Element =>
        editMode ? (
            <ButtonsContainer>
                <Cancel
                    onClick={() => {
                        onEditMode(false);
                    }}
                >
                    {t('admin.cancel')}
                </Cancel>
                <Send
                    disabled={updateInProgress}
                    onClick={() => {
                        onUpdateForm();
                    }}
                >
                    {t('admin.confirm')}
                </Send>
                {success === true && <img src={SuccessIcon} alt="" />}
            </ButtonsContainer>
        ) : (
            <ButtonsContainer>
                <Cancel onClick={onClose}>{t('admin.cancel')}</Cancel>
                <Send
                    onClick={() => {
                        onEditMode(true);
                    }}
                >
                    {t('admin.edit')}
                </Send>
            </ButtonsContainer>
        );

    if (!participant) {
        return <Loader />;
    }

    return (
        <ModalContainer>
            <Modal isBig={true}>
                {getCloseButton()}
                <Title>
                    {t('admin.participantInfoModal.title')}
                    {participant.id}
                </Title>
                <FieldsGroupContainer>
                    <FieldsGroup>
                        {getEnrollmentDateField()}
                        {getCompletionDateField()}
                        {getLanguageField()}
                        {getPlaceOfWorkField()}
                        {getStatusField()}
                    </FieldsGroup>
                    <FieldsGroup>
                        {getFirstNameField()}
                        {getLastNameField()}
                        {getEmailField()}
                        {getPhoneField()}
                        {getFirstAddressLineField()}
                        {getSecondAddressLineField()}
                        {getCityField()}
                        {getProvinceField()}
                        {getPostalCodeField()}
                    </FieldsGroup>
                    <FieldsGroup>
                        {getTestKitSentField()}
                        {getSampleReceivedAtLabField()}
                        {getLabStatusResultField()}
                    </FieldsGroup>
                </FieldsGroupContainer>
                {!updateInProgress ? getButtons() : <Loader />}
            </Modal>
        </ModalContainer>
    );
};
