import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

export const GroupedQuestionContainer = styled.div`
    display: grid;
    height: 40%;
    place-items: center;
    max-width: 350px;
    min-height: 200px;
`;

export const DateContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    width: 100%;
`;

export const Datehint = styled.div`
    font-family: PoppinsMedium;
    color: ${colours.darkGrey};

    @media ${sizes.XP} {
        font-size: 18px;
    }

    @media ${sizes.M} {
        font-size: 16px;
    }
`;
