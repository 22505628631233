/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

// Components
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';

// Constants
import CONSENT from '../../constants/consents';

// Styles
import { PageContainer } from '../../styles/common';
import {
    BottomText,
    CenterContainer,
    Disclaimer,
    DownloadPDF,
    IndicateConsent,
    PageIMG,
    FormContainer,
    RadioContainer,
    TopText,
    Virus1,
    Virus2,
    Virus3,
} from './styles';
import { OptionContainer, OptionLabel } from '../../components/CheckboxQuestion/styles';
import { CustomRadio, HiddenRadio } from '../../components/RadioQuestion/styles';

// Icon
import Virus from '../../assets/icons/virus.svg';

// Forms
import ConsentFormPDF_EN from '../../assets/Encore_Consent-Form-Professionals_ENG_jan2021.pdf';
import ConsentFormPDF_FR from '../../assets/Encore_Consent-Form-Professionals_FR_jan2021.pdf';
import ConsentFormEN_P1 from '../../assets/documents/EnCORE_consent_EN_p1.jpg';
import ConsentFormEN_P2 from '../../assets/documents/EnCORE_consent_EN_p2.jpg';
import ConsentFormEN_P3 from '../../assets/documents/EnCORE_consent_EN_p3.jpg';
import ConsentFormEN_P4 from '../../assets/documents/EnCORE_consent_EN_p4.jpg';
import ConsentFormEN_P5 from '../../assets/documents/EnCORE_consent_EN_p5.jpg';
import ConsentFormFR_P1 from '../../assets/documents/EnCORE_consent_FR_p1.jpg';
import ConsentFormFR_P2 from '../../assets/documents/EnCORE_consent_FR_p2.jpg';
import ConsentFormFR_P3 from '../../assets/documents/EnCORE_consent_FR_p3.jpg';
import ConsentFormFR_P4 from '../../assets/documents/EnCORE_consent_FR_p4.jpg';
import ConsentFormFR_P5 from '../../assets/documents/EnCORE_consent_FR_p5.jpg';

const Consent: React.FunctionComponent = () => {
    const [agreeToContact, setAgreeToContact] = useState<string>();
    const [agreeResearchData, setAgreeResearchData] = useState<string>();
    const [readConsent, setReadConsent] = useState<string>();

    const { t, i18n } = useTranslation();
    const language = i18n.language; // Detects language

    const getCorrectFormDownload = () => {
        if (language === 'en') {
            return ConsentFormPDF_EN;
        } else {
            return ConsentFormPDF_FR;
        }
    };

    const setConsentmentFields = (key: string, value: string) => {
        switch (key) {
            case CONSENT.CONTACT:
                setAgreeToContact(value);
                break;
            case CONSENT.SHARE:
                setAgreeResearchData(value);
                break;
            case CONSENT.PARTICIPATE:
                setReadConsent(value);
                break;
            default:
        }
        localStorage.setItem(key, value.toString());
    };

    const getCorrectFormDisplay = () => {
        if (language === 'en') {
            return (
                <>
                    <PageIMG src={ConsentFormEN_P1} alt={t('consent.consentForm')} />
                    <PageIMG src={ConsentFormEN_P2} alt={t('consent.consentForm')} />
                    <PageIMG src={ConsentFormEN_P3} alt={t('consent.consentForm')} />
                    <PageIMG src={ConsentFormEN_P4} alt={t('consent.consentForm')} />
                    <PageIMG src={ConsentFormEN_P5} alt={t('consent.consentForm')} />
                </>
            );
        } else {
            return (
                <>
                    <PageIMG src={ConsentFormFR_P1} alt={t('consent.consentForm')} />
                    <PageIMG src={ConsentFormFR_P2} alt={t('consent.consentForm')} />
                    <PageIMG src={ConsentFormFR_P3} alt={t('consent.consentForm')} />
                    <PageIMG src={ConsentFormFR_P4} alt={t('consent.consentForm')} />
                    <PageIMG src={ConsentFormFR_P5} alt={t('consent.consentForm')} />
                </>
            ); // Change thess for the French form when we receive it.
        }
    };

    return (
        <PageContainer>
            <Helmet>
                <title>{t('consent.consent')}</title>
            </Helmet>
            <Header />
            <Virus1 src={Virus} alt="Virus" />
            <Virus2 src={Virus} alt="Virus" />
            <Virus3 src={Virus} alt="Virus" />
            <CenterContainer>
                <TopText>{t('consent.topText')}</TopText>
                <FormContainer>{getCorrectFormDisplay()}</FormContainer>
                <DownloadPDF to={getCorrectFormDownload()} target="_blank" download>
                    {t('consent.downloadPDF')}
                </DownloadPDF>
                <BottomText>{t('consent.bottomText')}</BottomText>
                <RadioContainer>
                    <OptionContainer>
                        <CustomRadio
                            checked={agreeToContact === 'true'}
                            onClick={() => setConsentmentFields(CONSENT.CONTACT, 'true')}
                        />
                        <HiddenRadio
                            type="radio"
                            name={t('consent.yes')}
                            id={t('consent.yes')}
                            value={'yes'}
                            onChange={() => setConsentmentFields(CONSENT.CONTACT, 'true')}
                        />
                        <OptionLabel htmlFor={t('consent.yes')}>{t('consent.yes')}</OptionLabel>
                    </OptionContainer>
                    <OptionContainer>
                        <CustomRadio
                            checked={agreeToContact === 'false'}
                            onClick={() => setConsentmentFields(CONSENT.CONTACT, 'false')}
                        />
                        <HiddenRadio
                            type="radio"
                            name={t('consent.no')}
                            id={t('consent.no')}
                            value={'no'}
                            onChange={() => setConsentmentFields(CONSENT.CONTACT, 'false')}
                        />
                        <OptionLabel htmlFor={t('consent.no')}>{t('consent.no')}</OptionLabel>
                    </OptionContainer>
                </RadioContainer>
                <Disclaimer>{t('consent.disclaimer')}</Disclaimer>
                <BottomText>{t('consent.agreement')}</BottomText>
                <RadioContainer>
                    <OptionContainer>
                        <CustomRadio
                            checked={agreeResearchData === 'true'}
                            onClick={() => setConsentmentFields(CONSENT.SHARE, 'true')}
                        />
                        <HiddenRadio
                            type="radio"
                            name={t('consent.yes')}
                            id={t('consent.yes')}
                            value={'yes'}
                            onChange={() => setConsentmentFields(CONSENT.SHARE, 'true')}
                        />
                        <OptionLabel htmlFor={t('consent.yes')}>{t('consent.yes')}</OptionLabel>
                    </OptionContainer>
                    <OptionContainer>
                        <CustomRadio
                            checked={agreeResearchData === 'false'}
                            onClick={() => setConsentmentFields(CONSENT.SHARE, 'false')}
                        />
                        <HiddenRadio
                            type="radio"
                            name={t('consent.no')}
                            id={t('consent.no')}
                            value={'no'}
                            onChange={() => setConsentmentFields(CONSENT.SHARE, 'false')}
                        />
                        <OptionLabel htmlFor={t('consent.no')}>{t('consent.no')}</OptionLabel>
                    </OptionContainer>
                </RadioContainer>
                <IndicateConsent>{t('consent.indicateConsent')}</IndicateConsent>
                <BottomText>{t('consent.read')}</BottomText>
                <RadioContainer>
                    <OptionContainer>
                        <CustomRadio
                            checked={readConsent === 'true'}
                            onClick={() => setConsentmentFields(CONSENT.PARTICIPATE, 'true')}
                        />
                        <HiddenRadio
                            type="radio"
                            name={t('consent.yes')}
                            id={t('consent.yes')}
                            value={'yes'}
                            onChange={() => setConsentmentFields(CONSENT.PARTICIPATE, 'true')}
                        />
                        <OptionLabel htmlFor={t('consent.yes')}>{t('consent.yes')}</OptionLabel>
                    </OptionContainer>
                    <OptionContainer>
                        <CustomRadio
                            checked={readConsent === 'false'}
                            onClick={() => setConsentmentFields(CONSENT.PARTICIPATE, 'false')}
                        />
                        <HiddenRadio
                            type="radio"
                            name={t('consent.no')}
                            id={t('consent.no')}
                            value={'no'}
                            onChange={() => setConsentmentFields(CONSENT.PARTICIPATE, 'false')}
                        />
                        <OptionLabel htmlFor={t('consent.no')}>{t('consent.no')}</OptionLabel>
                    </OptionContainer>
                </RadioContainer>
            </CenterContainer>
            <Footer hasAnswer={readConsent === 'true'} goTo="/profile" />
        </PageContainer>
    );
};

export default Consent;
