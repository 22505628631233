/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Helmet } from 'react-helmet';

// Components
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { CheckboxQuestion } from '../../components/CheckboxQuestion';
import { RadioQuestion } from '../../components/RadioQuestion';
import { TextfieldQuestion } from '../../components/TextfieldQuestion';
import { DateQuestion } from '../../components/DateQuestion';
import { DropdownQuestion } from '../../components/DropdownQuestion';
import { NumberQuestion } from '../../components/NumberQuestion';
import { SelectboxQuestion } from '../../components/SelectboxQuestion';
import { MultiRadioQuestion } from '../../components/MultiRadioQuestion';
import { GroupedQuestion } from '../../components/GroupedQuestion';

// Styles
import {
    AnswersContainer,
    QuestionCounter,
    QuestionTitle,
    SurveyCenterContainer,
    TitleContainer,
} from './styles';
import { PageContainer } from '../../styles/common';
import Loader from '../../components/Loader';

// Slices and types
import {
    getQuestion,
    QuestionType,
    SectionType,
    saveQuestion,
    saveObject,
} from '../../slices/surveySlice';

// Utils
import { RootState } from '../../store';
import { MultitypeQuestion } from '../../components/MultitypeQuestion';

enum Question {
    checkbox = 'checkbox',
    radio = 'radio',
    text = 'text',
    date = 'date',
    dropdown = 'dropdown',
    number = 'number',
    select = 'select',
    multiradio = 'multiradio',
    multitype = 'multitype',
    grouped = 'groupedtype',
}
interface IProps {
    id: number;
}

const Survey: React.FunctionComponent<IProps> = (props: IProps) => {
    const [currentSection, setCurrentSection] = useState<number>(1); // This value holds the current section
    const [isLastQuestion, setIsLastQuestion] = useState<boolean>(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            history.push('/login');
        }
    });

    const question = useSelector((state: RootState) => state.survey.survey);
    const currentQuestion = useSelector((state: RootState) => state.survey.currentQuestion);

    useEffect(() => {
        dispatch(getQuestion(currentQuestion));
        dispatch(saveObject([]));
    }, [dispatch, currentQuestion]);

    if (!question) {
        return <Loader />;
    }

    const order = question.order;
    const surveyLength = question.totalQuestions;
    const sectionToDisplay: SectionType = question.section;
    const sectionLength = question.totalSectionQuestions;

    const canChangeCurrentQuestion = (question: QuestionType) => {
        const { question_option } = question;
        const hasSelectedOption =
            question_option.findIndex((option) => option.selected.length > 0) !== -1;

        const hasSelectedChild =
            question_option.findIndex(
                (father) =>
                    father.question_child.findIndex((child) => child.selected.length > 0) !== -1,
            ) !== -1;
        const hasAnswer = question.answer && question.answer.length > 0;

        return hasSelectedChild || hasSelectedOption || hasAnswer;
    };

    const renderElement = (element: QuestionType) => {
        switch (element.question_type.type) {
            case Question.checkbox:
                return (
                    <CheckboxQuestion
                        question={element.id}
                        options={element.question_option}
                        text={element.text}
                        key={element.id}
                    />
                );
            case Question.radio:
                return (
                    <RadioQuestion
                        question={element.id}
                        key={element.id}
                        text={element.text}
                        options={element.question_option}
                    />
                );
            case Question.text:
                return (
                    <TextfieldQuestion
                        question={element.id}
                        text={element.text}
                        key={element.id}
                        mask={element.text === 'postalCode' ? 'a9a-9a9' : ''}
                        value={element.answer}
                    />
                );
            case Question.date:
                return (
                    <DateQuestion
                        question={element.id}
                        text={element.text}
                        key={element.id}
                        value={element.answer}
                    />
                );
            case Question.dropdown:
                return (
                    <DropdownQuestion
                        question={element.id}
                        options={element.question_option}
                        text={element.text}
                        key={element.id}
                    />
                );
            case Question.number:
                return (
                    <NumberQuestion
                        question={element.id}
                        text={element.text}
                        key={element.id}
                        value={element.answer.length > 0 ? Number(element.answer[0].answer) : null}
                        min={element.min}
                        max={element.max}
                        subtype={element.subtype}
                    />
                );
            case Question.select:
                return <SelectboxQuestion question={{ ...element }} />;
            case Question.multiradio:
                return (
                    <MultiRadioQuestion
                        question={element.id}
                        options={element.question_option}
                        text={element.text}
                        key={element.id}
                    />
                );
            case Question.grouped:
                return (
                    <GroupedQuestion
                        question={element.id}
                        options={element.question_option}
                        value={element.answer}
                        key={element.id}
                    />
                );
            case Question.multitype:
                return (
                    <MultitypeQuestion
                        question={element.id}
                        options={element.question_option}
                        text={element.text}
                        key={element.id}
                        conditional={element.conditional_question}
                    />
                );
            default:
                return (
                    <div key={element.id}>
                        Question {element.order}: Component {element.question_type.type} to be
                        deployed soon
                    </div>
                );
        }
    };

    const onNext = () => {
        dispatch(saveQuestion(true));
        if (currentQuestion === surveyLength - 1) {
            setIsLastQuestion(true);
        }
        if (currentQuestion === sectionLength && currentSection !== surveyLength) {
            setCurrentSection(currentSection + 1);
        }
    };

    const onPrevious = () => {
        dispatch(saveQuestion(false));
        if (currentQuestion === 1 && currentSection !== 1) {
            setCurrentSection(currentSection - 1);
        }
        setIsLastQuestion(false);
    };

    return (
        <PageContainer>
            <Helmet>
                <title>{t('survey.questionnaire')}</title>
            </Helmet>
            <Header
                question={order}
                section={sectionToDisplay.order}
                sectionName={sectionToDisplay.name}
                sectionLength={sectionLength}
                onPrevious={onPrevious}
            />
            <SurveyCenterContainer>
                <TitleContainer>
                    <QuestionCounter>
                        {t('survey.question', { order, sectionLength })}
                    </QuestionCounter>
                    <QuestionTitle>{t(`survey.${question.text}`)}</QuestionTitle>
                </TitleContainer>
                <AnswersContainer>{renderElement(question)}</AnswersContainer>
            </SurveyCenterContainer>
            <Footer
                question={question}
                section={currentSection}
                onNext={onNext}
                onPrevious={onPrevious}
                hasAnswer={canChangeCurrentQuestion(question)}
                isLastQuestion={isLastQuestion}
            />
        </PageContainer>
    );
};

export default Survey;
