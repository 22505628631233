import React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

// Components
import { Header } from '../../components/Header';

import Family from '../../assets/icons/family.svg';

// Styles
import { PageContainer, ThankYouContainer, Image, Text1, Text2, Link } from './styles';
import { Title } from '../../styles/common';

const ThankYou: React.FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const language = i18n.language;

    const encoreStudyLink =
        language === 'en' ? 'https://www.encorestudy.ca/' : 'https://www.etudencore.ca/';

    return (
        <PageContainer>
            <Helmet>
                <title>{t('thankyou.helmet')}</title>
            </Helmet>
            <Header />
            <ThankYouContainer>
                <Title>{t('thankyou.title')}</Title>
                <Image src={Family} alt={t('thankyou.illustration')} />
                <Text1>{t('thankyou.text1')}</Text1>
                <Text2>
                    <Trans i18nKey="thankyou.text2">
                        Check out our daily update at
                        <Link href={encoreStudyLink} target="_blank">
                            encorestudy.ca
                        </Link>
                    </Trans>
                </Text2>
            </ThankYouContainer>
        </PageContainer>
    );
};

export default ThankYou;
