import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from './pages/home';
import Survey from './pages/survey';
import Consent from './pages/consent';
import Profile from './pages/profile';
import Login from './pages/login';
import ResetPassword from './pages/password-reset';
import SendResetPassword from './pages/password-reset/send-password-reset';
import ThankYou from './pages/thank_you';
import Admin from './pages/admin';
import AdminLogin from './pages/adminLogin';
import SignupConfirmation from './pages/signup-confirmation';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import { ROUTES } from './constants/routes';

const Routes: React.FunctionComponent = () => {
    return (
        <>
            <Switch>
                {/* Private routes */}
                <PrivateRoute path={ROUTES.SURVEY}>
                    <Survey id={1} />
                </PrivateRoute>
                <PrivateRoute path={ROUTES.THANK_YOU}>
                    <ThankYou />
                </PrivateRoute>
                <PrivateRoute path={ROUTES.ADMIN} isAdminRoute>
                    <Admin />
                </PrivateRoute>

                {/* Public routes, redirect if authenticated */}
                <PublicRoute path={ROUTES.CONSENT}>
                    <Consent />
                </PublicRoute>
                <PublicRoute path={ROUTES.PROFILE}>
                    <Profile />
                </PublicRoute>
                <PublicRoute path={ROUTES.LOGIN}>
                    <Login />
                </PublicRoute>
                <PublicRoute path={ROUTES.ADMIN_LOGIN}>
                    <AdminLogin />
                </PublicRoute>
                <PublicRoute path={ROUTES.SIGNUP_CONFIRMATION}>
                    <SignupConfirmation />
                </PublicRoute>

                {/* Public routes */}
                <Route path={ROUTES.HOME} exact>
                    <HomePage />
                </Route>
                <Route path={['/reset-password/:token', '/signup/:token']}>
                    <ResetPassword />
                </Route>
                <Route path="/reset-password">
                    <SendResetPassword />
                </Route>
            </Switch>
        </>
    );
};

export default Routes;
