import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

export const IllustrationMobile = styled.img`
    width: 85%;
    margin: 40px 0;

    @media ${sizes.M} {
        display: none;
    }
`;

export const Title = styled.div`
    color: ${colours.pink};
    font-family: NunitoBold;
    text-align: center;

    @media ${sizes.XP} {
        font-size: 30px;
    }

    @media ${sizes.G} {
        font-size: 36px;
    }

    @media ${sizes.XG} {
        font-size: 46px;
    }
`;

export const Subtitle = styled.div`
    color: ${colours.black};
    font-family: NunitoSemiBold;
    text-align: center;
    white-space: pre-line;

    @media ${sizes.XP} {
        font-size: 22px;
    }

    @media ${sizes.G} {
        font-size: 28px;
    }

    @media ${sizes.XG} {
        font-size: 36px;
    }
`;

export const Subtitle2 = styled.div`
    color: ${colours.black};
    font-family: NunitoRegular;
    text-align: center;
    white-space: pre-line;

    @media ${sizes.XP} {
        font-size: 16px;
    }

    @media ${sizes.XG} {
        font-size: 18px;
    }
`;

export const Login = styled(NavLink)`
    display: grid;
    place-items: center;
    text-decoration: none;
    font-family: 'NunitoBold';
    margin: 0 20px;
`;
