import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../constants/routes';
import { RootState } from '../store';
import { HTTPGetRequest } from '../utils/request';
import { IUser } from './authSlice';

export interface IAdminState {
    users?: IUser[];
    loading: boolean;
    error?: string;
    nextPage?: string;
    previousPage?: string;
}

export interface IFetchUsersParams {
    searchTerm: string;
    isAdmin: boolean;
    orderBy?: string;
    order?: boolean;
}

export const initialState: IAdminState = {
    users: [],
    loading: false,
};

export const fetchUsers = createAsyncThunk(
    'fetch/users',
    async (params: IFetchUsersParams, { rejectWithValue, dispatch }) => {
        try {
            const { searchTerm, isAdmin, orderBy, order } = params;

            let query = '';
            let fields = `&fields=[email,profile.firstName,profile.lastName]&match=any`;

            if (searchTerm !== '') {
                query += `&query=${searchTerm}`;
            }

            if (orderBy && typeof order !== 'undefined') {
                query += `&orderBy=${orderBy}&order=${order ? 'asc' : 'desc'}`;
            }

            if (!Number.isNaN(Number(searchTerm))) {
                fields = `&fields=[id]&match=exact`;
            }

            const { data: response } = await HTTPGetRequest<IUser[]>(
                `${BACKEND_ROUTES.USERS}?page=0&pageSize=10${fields}${query}`,
            );

            if (isAdmin) {
                return response.data.filter(
                    (user) => user.role === 'admin' || user.role === 'coordinator',
                );
            }
            dispatch(setNextPage(response.next));
            dispatch(setPreviousPage(response.prev));

            return response.data;
        } catch (e) {
            const error = e as AxiosError<string>;
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    },
);

export const changePage = createAsyncThunk<IUser[], boolean, { state: RootState }>(
    'fetch/nextPage',
    async (nextPage, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState();

            let query;

            if (nextPage && state.admin.nextPage) {
                query = `${BACKEND_ROUTES.BASE_URL}${state.admin.nextPage}`;
            }

            if (!nextPage && state.admin.previousPage) {
                query = `${BACKEND_ROUTES.BASE_URL}${state.admin.previousPage}`;
            }

            if (!query) {
                return state.admin.users ?? [];
            }

            const { data: response } = await HTTPGetRequest<IUser[]>(query);

            dispatch(setNextPage(response.next));
            dispatch(setPreviousPage(response.prev));

            return response.data;
        } catch (e) {
            const error = e as AxiosError<string>;
            if (error.response?.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    },
);

export const slice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        setNextPage(state, action: PayloadAction<string | undefined>) {
            state.nextPage = action.payload;
        },
        setPreviousPage(state, action: PayloadAction<string | undefined>) {
            state.previousPage = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchUsers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
            state.users = [...payload];
            state.loading = false;
        });
        builder.addCase(fetchUsers.rejected, (state, { payload }) => {
            if (payload) {
                state.loading = false;
            }
        });
        builder.addCase(changePage.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(changePage.fulfilled, (state, { payload }) => {
            state.users = [...payload];
            state.loading = false;
        });
        builder.addCase(changePage.rejected, (state, { payload }) => {
            if (payload) {
                state.loading = false;
            }
        });
    },
});

export const { setPreviousPage, setNextPage } = slice.actions;

export default slice.reducer;
