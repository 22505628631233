const { REACT_APP_CANADA_POST_API_KEY } = process.env;

const apiKey = REACT_APP_CANADA_POST_API_KEY;

const canadaPost = {
    search(search: string, id: string) {
        const encodedSearch = encodeURIComponent(search);
        const encodedCountry = encodeURIComponent('CAN');
        const encodedMaxResults = encodeURIComponent(8);
        let lastId = '';
        if (id) {
            lastId = encodeURIComponent(id);
        }
        return `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws?Key=${apiKey}&SearchTerm=${encodedSearch}&LastId=${lastId}&Country=${encodedCountry}&MaxResults=${encodedMaxResults}`;
    },
    getDetails(id: string) {
        const encodedId = encodeURIComponent(id);

        return `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws?Key=${apiKey}&Id=${encodedId}`;
    },
};

export default canadaPost;
