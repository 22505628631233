import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../store';
import { ROUTES } from '../../constants/routes';
import { loginUser } from '../../slices/authSlice';

import ROLES from '../../constants/roles';

// Styles
import { Text, Container } from './styles';
import { Fields, Label, LoginField, Error, WelcomeTextContainer, ResetLink } from '../login/styles';
import { Button } from '../../components/Button';
import { Title } from '../../styles/common';

import HomeLayout from '../../layouts/home';

interface IProps {}

const AdminLogin = withRouter((props: IProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const authError = useSelector((state: RootState) => state.auth.errors);
    const user = useSelector((state: RootState) => state.auth.user);
    const userRole = user ? user.role : undefined;

    // If user is authenticated, redirect to admin home. If user is not admin or coordinator, redirect to survey page.
    if (user?.id && userRole !== ROLES.ADMIN && userRole !== ROLES.COORDINATOR) {
        history.push(ROUTES.SURVEY);
    } else if (userRole === ROLES.ADMIN || userRole === ROLES.COORDINATOR) {
        history.push(ROUTES.ADMIN);
    }

    const authHandler = () => {
        // In case the user wrongly leaves whitespace in the fields
        setEmail(email.trim());
        setPassword(password.trim());
        dispatch(loginUser({ email, password }));
    };

    return (
        <>
            <Helmet>
                <title>{t('adminLogin.helmet')}</title>
            </Helmet>
            <HomeLayout>
                <Container>
                    <Title>{t('adminLogin.title')}</Title>
                    <WelcomeTextContainer>
                        <Text>
                            <Trans i18nKey="adminLogin.text">
                                This page is for admins and coordinators login only. If you are a
                                participant, you can login by
                                <ResetLink to="/login">clicking here</ResetLink>.
                            </Trans>
                        </Text>
                        <Text>
                            <Trans i18nKey="login.lostPassword">
                                If you have lost your password, you can reset it by clicking
                                <ResetLink to="/reset-password">here</ResetLink>.
                            </Trans>
                        </Text>
                    </WelcomeTextContainer>
                    <Fields>
                        <Label htmlFor="login">{t('login.username')}</Label>
                        <LoginField
                            type="text"
                            name="login"
                            id="login"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </Fields>
                    <Fields>
                        <Label htmlFor="password">{t('login.password')}</Label>
                        <LoginField
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                        {authError && <Error>{t('login.authError')}</Error>}
                    </Fields>
                    <Button label={t('login.login')} onClick={authHandler} justifySelf="center" />
                </Container>
            </HomeLayout>
        </>
    );
});

export default AdminLogin;
