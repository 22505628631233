import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colours } from '../../constants/colours';
import { sizes } from '../../constants/mediaSizes';
import { boxShadowUp, ButtonStyleProps } from '../../styles/common';

export const BodyContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    height: 70px;
    width: 100vw;
    background-color: ${colours.white};
    box-shadow: ${boxShadowUp};
    position: fixed;
    bottom: 0;

    @media ${sizes.XP} {
        grid-template-rows: 55% 1fr;
        height: 100px;
    }

    @media ${sizes.M} {
        height: 70px;
        justify-content: flex-end;
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 10;

    @media ${sizes.XP} {
        justify-content: center;
        height: 70px;
    }

    @media ${sizes.M} {
        justify-content: flex-end;
    }
`;

export const LogoutButton = styled.div`
    color: ${colours.purple};
    font-size: 18px;
    cursor: pointer;
    width: 100%;
    text-align: center;

    @media ${sizes.XP} {
        display: revert;
        place-self: center;
    }

    @media ${sizes.M} {
        display: none;
    }
`;

export const NavNextButton = styled.button<ButtonStyleProps>`
    background-color: ${(props) => (props.active ? colours.purple : colours.lightGrey)};
    color: ${(props) => (props.active ? colours.white : colours.darkGrey)};
    border-radius: 10px;
    border: none;
    font-family: NunitoBold;
    transition: all 0.5s ease;
    cursor: pointer;

    @media ${sizes.XP} {
        width: 90%;
        height: 70%;
        font-size: 24px;
    }

    @media ${sizes.M} {
        width: 142px;
        height: 50px;
        font-size: 22px;
        margin: 10px 20px;
    }

    &:hover {
        background-color: ${(props) => (props.active ? colours.purpleHover : colours.mediumGrey)};
    }
`;

export const NavPreviousButton = styled.button<ButtonStyleProps>`
    background-color: ${colours.white};
    color: ${(props) => (props.active ? colours.purple : colours.darkGrey)};
    border: none;
    border-radius: 10px;
    font-family: NunitoBold;
    transition: all 0.5s ease;
    cursor: pointer;

    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.M} {
        display: revert;
        font-size: 22px;
    }

    &:hover {
        color: ${(props) => (props.active ? colours.purpleHover : colours.mediumGrey)};
    }
`;

export const ButtonContainer = styled(Link)`
    @media ${sizes.XP} {
        width: 355px;
        text-align: center;
    }

    @media ${sizes.M} {
        width: revert;
    }
`;
