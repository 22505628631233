import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { BACKEND_ROUTES } from '../constants/routes';
import { RootState } from '../store';
import { IUser } from './authSlice';

export interface IError {
    message: string;
}

export interface IParticipantGetState {
    participantInfo: IUser | null;
    error: IError | string | null;
    inProgress: boolean;
}

export interface IParticipantInfo extends IUser {
    survey?: {
        workplace: string;
        completedAt: string;
    };
}

export const initialState: IParticipantGetState = {
    participantInfo: null,
    error: null,
    inProgress: false,
};

export const clearGetParticipantInfo = createAsyncThunk(
    'get/participantInfo/clear',
    (_, { dispatch }) => {
        dispatch(clear());
    },
);
export const getParticipantInfo = createAsyncThunk(
    'get/participantInfo',
    async (participantId: number, { dispatch, rejectWithValue }) => {
        dispatch(setGetParticipantInfoInProgress(true));

        const token: string | null = localStorage.getItem('token');
        const config: {
            headers?: {};
        } = {};
        if (token) {
            config.headers = {
                authorization: `Bearer ${token}`,
            };
        }
        try {
            const response = await axios.get(
                `${BACKEND_ROUTES.USERS}/${participantId}?with_survey`,
                config,
            );

            dispatch(setGetParticipantInfoAction(response.data.data));
        } catch (e) {
            const error = e as AxiosError<string>;
            let errorMessage: string | null = null;

            if (error.response?.status === 404) {
                errorMessage = "Participant doesn't exist";
            } else if (error.response?.status === 401) {
                errorMessage = 'Unauthorized';
            } else if (error.response?.status === 403) {
                errorMessage = 'Forbidden';
            } else {
                errorMessage = 'An unexpected error as occurred.';
            }

            dispatch(setGetParticipantInfoErrorAction(errorMessage));
            return rejectWithValue(errorMessage);
        }
    },
);

export const slice = createSlice({
    name: 'getParticipantInfo',
    initialState,
    reducers: {
        setGetParticipantInfoAction(state, action) {
            state.participantInfo = action.payload;
            state.error = null;
            state.inProgress = false;
        },
        setGetParticipantInfoErrorAction(state, action) {
            state.participantInfo = null;
            state.error = action.payload;
            state.inProgress = false;
        },
        setGetParticipantInfoInProgress(state, action) {
            state.participantInfo = null;
            state.error = null;
            state.inProgress = action.payload;
        },
        clear(state) {
            state.participantInfo = null;
            state.error = null;
            state.inProgress = false;
        },
    },
});

export const selectGetParticipantInfo = (state: RootState) =>
    state.getParticipantInfo.participantInfo;
export const selectGetParticipantInfoError = (state: RootState) => state.getParticipantInfo.error;

export const {
    setGetParticipantInfoAction,
    setGetParticipantInfoInProgress,
    setGetParticipantInfoErrorAction,
    clear,
} = slice.actions;

export default slice.reducer;
