import styled from 'styled-components';

import { sizes } from '../../constants/mediaSizes';

export const RadioGroup = styled.div`
    display: flex;
    flex-direction: row;
    width: 332px;
`;

export const FieldsGroupContainer = styled.div`
    display: grid;
    grid-template-rows: auto;
    row-gap: 35px;
`;

export const FieldsGroup = styled.div`
    display: grid;
    column-gap: 30px;

    @media ${sizes.M} {
        grid-template-columns: auto auto;
    }

    @media ${sizes.XG} {
        grid-template-columns: auto auto auto;
    }
`;
