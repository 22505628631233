import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// Styles
import {
    Container,
    DownloadButton,
    DownloadMenu,
    DownloadMenuItem,
    PageTitle,
    DownloadText,
    Icon,
    Arrow,
} from './styles';

import DownloadIcon from '../../assets/icons/download_icon.svg';
import DownArrow from '../../assets/icons/dropdown_arrow_white.svg';

import { BACKEND_ROUTES } from '../../constants/routes';

const AdminDownloadHeader: React.FunctionComponent = () => {
    const [showDownloadMenu, setShowDownloadMenu] = useState<boolean>(false);

    const { t } = useTranslation();

    const downloadUsers = async () => {
        return downloadCSV(BACKEND_ROUTES.DOWNLOAD_USER_DATA, 'participants.csv');
    };

    const downloadQuestions = () => {
        return downloadCSV(BACKEND_ROUTES.DOWNLOAD_SURVEY_DATA, 'questionnaire.csv');
    };

    const downloadCSV = async (url: string, fileName: string) => {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            responseType: 'blob',
        });
        const responseUrl = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = responseUrl;
        // link.target = '_blank';
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    };

    return (
        <Container>
            <PageTitle>Participant</PageTitle>
            <DownloadButton onClick={() => setShowDownloadMenu((prevState) => !prevState)}>
                <Icon src={DownloadIcon} />
                <DownloadText>{t('admin.downloadButton')}</DownloadText>
                <Arrow src={DownArrow} />
            </DownloadButton>
            {showDownloadMenu && (
                <DownloadMenu>
                    <DownloadMenuItem aria-hidden="true" onClick={downloadUsers}>
                        {t('admin.downloadUsers')}
                    </DownloadMenuItem>
                    <DownloadMenuItem aria-hidden="true" onClick={downloadQuestions}>
                        {t('admin.downloadQuestions')}
                    </DownloadMenuItem>
                </DownloadMenu>
            )}
        </Container>
    );
};

export default AdminDownloadHeader;
