import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import validator from 'validator';

import SuccessIcon from '../../assets/icons/success.svg';

import {
    LoginField,
    Error,
    Label,
    Fields,
    WelcomeTextContainer,
    TextContainer,
    Container,
} from './styles';

import { Button } from '../../components/Button';

import HomeLayout from '../../layouts/home';

import { BACKEND_ROUTES } from '../../constants/routes';

const SendPasswordReset: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);

    const { t } = useTranslation();

    const resetPassword = async () => {
        setError(!validator.isEmail(email));

        try {
            const response = await axios.post<{ data: any }>(BACKEND_ROUTES.SEND_RESET_PASSWORD, {
                email,
            });
            setSent(response.status === 204);
            setError(response.status !== 204);
        } catch (error) {
            setError(true);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('login.title')}</title>
            </Helmet>
            <HomeLayout>
                <Container>
                    <WelcomeTextContainer>
                        <p>{t('login.resetPassword')}</p>
                    </WelcomeTextContainer>
                    {sent && (
                        <TextContainer>
                            <img src={SuccessIcon} alt="email_sent_img" />{' '}
                            {t('login.resetLinkSent')}
                        </TextContainer>
                    )}
                    {!sent && <TextContainer>{t('login.enterYourEmail')}</TextContainer>}
                    <Fields>
                        <Label htmlFor="email">{t('profile.email')}</Label>
                        <LoginField
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        {error && <Error>{t('login.nonExistantEmail')}</Error>}
                    </Fields>
                    <Button
                        label={t('login.sendLink')}
                        onClick={resetPassword}
                        justifySelf="center"
                    />
                </Container>
            </HomeLayout>
        </>
    );
};

export default SendPasswordReset;
