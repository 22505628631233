import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import {
    LoginField,
    Error,
    Label,
    Fields,
    WelcomeTextContainer,
    Container,
    LoginHint,
} from './styles';

import { Button } from '../../components/Button';
import Loader from '../../components/Loader';

import HomeLayout from '../../layouts/home';

import { BACKEND_ROUTES } from '../../constants/routes';

export interface IParams {
    token: string;
}

const ResetPassword: React.FC = () => {
    const [password1, setPassword1] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [updatedPassword, setUpdatedPassword] = useState<boolean>(false);

    const history = useHistory();

    const { t } = useTranslation();
    const params = useParams<IParams>();

    const isSignup = useRouteMatch('/signup/:token');

    const resetPassword = async () => {
        setError(password1 !== password2);
        if (password1 !== password2) return;
        setLoading(true);
        try {
            const { status } = await axios.post<{ data: any }>(BACKEND_ROUTES.RESET_PASSWORD, {
                password: password1,
                token: params.token,
            });

            if (status === 204) {
                setUpdatedPassword(true);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const buttonLabel = () => {
        if (updatedPassword) return t('login.goToLogin');
        if (isSignup) return t('login.createPassword');
        return t('login.resetPassword');
    };

    const welcomeText = () => {
        if (updatedPassword) return t('login.passwordCreatedText');
        return buttonLabel();
    };

    const onButtonClick = () => {
        if (updatedPassword) return history.push('/login');
        return resetPassword();
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Helmet>
                <title>{t('login.title')}</title>
            </Helmet>
            <HomeLayout>
                <Container>
                    <WelcomeTextContainer>
                        <p>{welcomeText()}</p>
                    </WelcomeTextContainer>
                    {!updatedPassword && (
                        <>
                            <Fields>
                                <Label htmlFor="password1">{t('login.newPassword1')}</Label>
                                <LoginField
                                    type="password"
                                    name="password1"
                                    id="password1"
                                    value={password1}
                                    onChange={(event) => setPassword1(event.target.value)}
                                />
                            </Fields>
                            <Fields>
                                <Label htmlFor="password2">{t('login.newPassword2')}</Label>
                                <LoginField
                                    type="password"
                                    name="password2"
                                    id="password2"
                                    value={password2}
                                    onChange={(event) => setPassword2(event.target.value)}
                                />
                                {error && <Error>{t('login.matchPasswords')}</Error>}
                            </Fields>
                        </>
                    )}
                    <Button label={buttonLabel()} onClick={onButtonClick} justifySelf="center" />
                    {!updatedPassword && <LoginHint to="/login">{t('login.loginHint')}</LoginHint>}
                </Container>
            </HomeLayout>
        </>
    );
};

export default ResetPassword;
