import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colours } from '../../constants/colours';

export const TextContainer = styled.p`
    justify-self: center;
    margin: 50px;
`;

export const LoginField = styled.input`
    justify-self: center;
    font-family: PoppinsRegular;
    width: 100%;
    height: 48px;
    border: 1px solid ${colours.mediumGrey};
    border-radius: 7px;
`;

export const Error = styled.span`
    font-family: PoppinsRegular;
    color: ${colours.errorRed};
`;

export const Label = styled.label`
    display: block;
    text-align: left;
`;

export const Fields = styled.div`
    justify-self: center;
    width: 70%;
`;

export const WelcomeTextContainer = styled.div`
    margin: 50px;
    justify-self: center;
    font-family: NunitoBold;
    font-size: 30px;
    color: ${colours.purple};
`;

export const Container = styled.div`
    display: grid;
    place-items: center;
    grid-template-rows: auto;
    width: 100%;
`;

export const LoginHint = styled(Link)`
    text-decoration: none;
    font-family: NunitoBold;
    color: ${colours.purple};
`;
