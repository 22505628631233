/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { RootState } from '../../store';

import CloseIcon from '../../assets/icons/close_icon.svg';

import {
    Modal,
    ModalContainer,
    Cancel,
    Send,
    CloseButton,
    Title,
    Label,
    Field,
    FieldContainer,
    ButtonsContainer,
} from '../../styles/modal';
import { FieldError } from '../../styles/common';
import { BACKEND_ROUTES } from '../../constants/routes';
import Loader from '../Loader';
import { updateProfile } from '../../slices/updateProfileSlice';

export interface IProps {
    onCloseButton: (close: boolean) => void;
}

export const AdminPasswordModal: React.FunctionComponent<IProps> = ({ onCloseButton }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');
    const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
    const [blankPassword, setBlankPassword] = useState<boolean>(false);
    const [oldPasswordError, setOldPasswordError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const token = localStorage.getItem('token');
    const headers = {
        headers: {
            authorization: `Bearer ${token}`,
        },
    };

    if (loading || !user) {
        return <Loader />;
    }

    const changePassword = () => {
        if (newPassword === '' || oldPassword === '') {
            setBlankPassword(true);
            setNewPasswordError(false);
        } else if (newPassword !== newPasswordConfirm) {
            setNewPasswordError(true);
            setBlankPassword(false);
        } else {
            setLoading(true);
            try {
                const values = {
                    id: user.id,
                    email: user.email,
                    password: newPassword,
                    profile: {
                        id: user.profile.id,
                        firstName: user.profile.firstName,
                        lastName: user.profile.lastName,
                        postalCode: user.profile.postalCode,
                        firstAddressLine: user.profile.firstAddressLine,
                        secondAddressLine: user.profile.secondAddressLine,
                        city: user.profile.city,
                        province: user.profile.province,
                    },
                };
                dispatch(updateProfile(values));
                setSuccess(true);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const checkPassword = async () => {
        try {
            const { status } = await axios.post<{ data: any }>(
                BACKEND_ROUTES.CHECK_PASSWORD,
                {
                    password: oldPassword,
                },
                headers,
            );
            if (status === 204) {
                setOldPasswordError(false);
                changePassword();
            } else {
                setOldPasswordError(true);
            }
        } catch (error) {
            console.log(error);
            setOldPasswordError(true);
        }
    };

    const sendUpdate = () => {
        if (success) {
            onCloseButton(false);
        } else {
            checkPassword();
        }
    };

    return (
        <ModalContainer>
            <Modal>
                <CloseButton onClick={() => onCloseButton(false)}>
                    <img src={CloseIcon} alt="close" />
                </CloseButton>
                <Title>{success ? t('login.updatedPassword') : t('admin.modifyPassword')}</Title>
                {!success && (
                    <>
                        <FieldContainer>
                            <Label>{t('admin.oldPassword')}</Label>
                            <Field
                                type="password"
                                value={oldPassword}
                                onChange={(event) => setOldPassword(event.target.value)}
                            ></Field>
                            {oldPasswordError && (
                                <FieldError>{t('admin.incorrectPassword')}</FieldError>
                            )}
                        </FieldContainer>
                        <FieldContainer>
                            <Label>{t('login.newPassword1')}</Label>
                            <Field
                                type="password"
                                value={newPassword}
                                onChange={(event) => setNewPassword(event.target.value)}
                            ></Field>
                        </FieldContainer>
                        <FieldContainer>
                            <Label>{t('login.newPassword2')}</Label>
                            <Field
                                type="password"
                                value={newPasswordConfirm}
                                onChange={(event) => {
                                    setNewPasswordConfirm(event.target.value);
                                }}
                            ></Field>
                            {blankPassword && <FieldError>{t('admin.noBlank')}</FieldError>}
                            {newPasswordError && (
                                <FieldError>{t('login.matchPasswords')}</FieldError>
                            )}
                        </FieldContainer>
                    </>
                )}
                <ButtonsContainer>
                    {!success && (
                        <Cancel onClick={() => onCloseButton(false)}>{t('admin.cancel')}</Cancel>
                    )}
                    <Send onClick={sendUpdate}>{success ? 'Close' : t('admin.send')}</Send>
                </ButtonsContainer>
            </Modal>
        </ModalContainer>
    );
};
