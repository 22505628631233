/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import validator from 'validator';

import { signout } from '../../slices/authSlice';

import {
    BodyContainer,
    ButtonContainer,
    FooterContainer,
    LogoutButton,
    NavNextButton,
    NavPreviousButton,
} from './styles';
import { RootState } from '../../store';
import { QuestionType } from '../../slices/surveySlice';
import { QuestionTypeId } from '../../constants/questionTypes';

export interface IProps {
    question?: QuestionType;
    section?: number;
    onNext?: () => void;
    onPrevious?: () => void;
    hasAnswer: boolean;
    goTo?: string;
    isLastQuestion?: boolean;
}

export const Footer: React.FunctionComponent<IProps> = ({
    question,
    section,
    onNext,
    onPrevious,
    hasAnswer,
    goTo,
    isLastQuestion,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const objSaveObject = useSelector((state: RootState) => state.survey.saveObject);
    const isButtonDisabled = () => {
        let isValid = false;
        switch (question?.questionTypeId) {
            case QuestionTypeId.text:
                isValid =
                    (objSaveObject.length > 0 &&
                        objSaveObject[0].answer !== '' &&
                        !validator.contains(objSaveObject[0].answer, '_')) ||
                    hasAnswer;
                break;
            case QuestionTypeId.select:
                if (objSaveObject.length > 0 && !objSaveObject[0].is_custom) {
                    isValid = objSaveObject.length > 0 && objSaveObject[0].answer_offered_id;
                } else {
                    isValid = objSaveObject.length > 0 && objSaveObject[0].custom_answer;
                }

                break;
            case QuestionTypeId.date:
                isValid = objSaveObject.length > 0 && validator.isDate(objSaveObject[0].answer);
                break;
            case QuestionTypeId.multitype:
                const answer = question?.conditional_question?.answer[0]?.answer;
                const answerTypes = objSaveObject.map((answer: any) => {
                    return {
                        type: answer.type,
                        index: answer.order,
                    };
                });
                const uniqueAnswerTypes: any = [];
                for (const typeObj of answerTypes) {
                    if (
                        uniqueAnswerTypes.filter(
                            (elm: any) => elm.type === typeObj.type && elm.index === typeObj.index,
                        ).length === 0 ||
                        typeObj.type !== 'checkbox'
                    ) {
                        uniqueAnswerTypes.push(typeObj);
                    }
                }

                isValid = uniqueAnswerTypes.length >= question.question_option.length * answer;
                break;
            case QuestionTypeId.multiradio:
                // by default, just checks if we answered all the questions
                isValid = objSaveObject.length === question.question_option.length;

                // for question 64, we have a hidden option, so we need some validation
                // and its the only multi radio question that does that.
                if (question.id === 64) {
                    // get all the radio options
                    const childs = question.question_option
                        .map((option) => option.question_child)
                        .flat();
                    const selectedTexts = [];

                    // for each selected option, get its textkey in the radio option
                    for (const saveObject of objSaveObject) {
                        const { answer_offered_id } = saveObject;
                        const childAnswer = childs.filter(
                            (child) => child.id === answer_offered_id,
                        )[0];
                        selectedTexts.push(childAnswer.text);
                    }

                    // get the first answer index different than "not all all"
                    const differentThanNotAtAllIndex = selectedTexts.findIndex(
                        (text) => text !== 'notAtAll',
                    );

                    // if there is some answer different than not at all, we should remove the last question from the validation
                    if (differentThanNotAtAllIndex === -1) {
                        isValid = objSaveObject.length === question.question_option.length - 1;
                    }
                }
                break;
            default:
                isValid = objSaveObject.length > 0 || hasAnswer;
        }

        return !isValid;
    };

    if (question && section) {
        return (
            <BodyContainer>
                <FooterContainer>
                    {question.id > 1 && (
                        <NavPreviousButton onClick={onPrevious} active={question.id > 1}>
                            {t('footer.previous')}
                        </NavPreviousButton>
                    )}
                    {!isLastQuestion && (
                        <NavNextButton
                            onClick={onNext}
                            active={!isButtonDisabled()}
                            disabled={isButtonDisabled()}
                        >
                            {t('footer.next')}
                        </NavNextButton>
                    )}
                    {isLastQuestion && (
                        <ButtonContainer to="/thankyou">
                            <NavNextButton onClick={onNext} active={hasAnswer}>
                                {t('footer.next')}
                            </NavNextButton>
                        </ButtonContainer>
                    )}
                </FooterContainer>
                <LogoutButton
                    onClick={() =>
                        dispatch(
                            signout(() => {
                                history.push('/login');
                            }),
                        )
                    }
                >
                    {t('header.saveAndContinueLater')}
                </LogoutButton>
            </BodyContainer>
        );
    }

    return (
        <FooterContainer>
            <ButtonContainer to={hasAnswer && goTo ? goTo : '#'}>
                <NavNextButton onClick={onNext} active={hasAnswer}>
                    {t('footer.next')}
                </NavNextButton>
            </ButtonContainer>
        </FooterContainer>
    );
};
