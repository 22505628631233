/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isMatch } from 'date-fns';
import { useDispatch } from 'react-redux';

import { Question } from '../../constants/questionTypes';

import { DateContainer, Datefield, Datehint, DateError } from './styles';

import { saveObject } from '../../slices/surveySlice';

export interface IProps {
    question: number;
    questionOptionId?: number;
    text: string;
    index?: number;
    value?: any;
    minYear?: number;
    maxYear?: number;
}

export const DateQuestion: React.FC<IProps> = ({
    question,
    questionOptionId,
    index,
    text,
    value,
    minYear,
    maxYear,
}) => {
    const { errors, control, setError, clearErrors } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldFocusError: true,
    });

    const dispatch = useDispatch();

    const [answer, setAnswer] = useState<string>('');

    const { t } = useTranslation();

    useEffect(() => {
        if (value && value.length > 0) {
            setAnswer(value[0].answer);

            // In case of multiple types, we need to add to the store the loaded answers so we don't lose their values in edition
            if (index) {
                dispatch(
                    saveObject({
                        question_id: question,
                        answer: value[0].answer,
                        answer_offered_id: questionOptionId,
                        order: index,
                        type: Question.date,
                    }),
                );
            } else {
                dispatch(
                    saveObject({
                        question_id: question,
                        answer: value[0].answer,
                        type: Question.date,
                    }),
                );
            }
        }
    }, []);

    const handleOnChange = (date: string) => {
        const isDateValid = isMatch(date, 'yyyy-MM-dd');

        if (!isDateValid) {
            setError(text, {
                type: 'manual',
                message: 'Invalid date',
            });
            handleAnswer(date);
        } else {
            clearErrors(text);
            handleAnswer(date);
        }
    };

    const handleAnswer = (value: string) => {
        setAnswer(value);

        dispatch(
            saveObject({
                question_id: question,
                answer: value,
                answer_offered_id: questionOptionId,
                order: index,
                type: Question.date,
            }),
        );
    };
    return (
        <DateContainer>
            <Datehint>{t(`survey.${text}`)}</Datehint>
            <Controller
                render={() => {
                    return (
                        <Datefield
                            mask="9999-99-99"
                            value={answer}
                            onChange={(event) => handleOnChange(event.target.value)}
                        />
                    );
                }}
                control={control}
                value={answer}
                name={text}
            />
            {errors[text] && <DateError>{t('survey.dateError')}</DateError>}
        </DateContainer>
    );
};
