import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

import DropdownArrow from '../../assets/icons/dropdown_arrow.svg';

export const Q1Container = styled.div`
    display: grid;
    height: 50%;

    @media ${sizes.XP} {
        width: 90%;
    }

    @media ${sizes.M} {
        width: 420px;
    }
`;

export const DropdownOuterContainer = styled.div`
    @media ${sizes.XP} {
        width: 90%;
    }

    @media ${sizes.M} {
        width: 420px;
    }
`;

export const DropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const DropdownTitle = styled.div`
    font-family: PoppinsMedium;
    color: ${colours.darkGrey};
    font-size: 16px;
`;

export const Select = styled.select`
    width: 100%;
    font-family: PoppinsRegular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 48px;
    border-radius: 7px;
    border: solid 1px ${colours.darkGrey};
    background-image: url(${DropdownArrow}) !important;
    background-position: 96% center;
`;
