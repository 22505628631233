import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios';
import validator from 'validator';

// Components
import { useHistory } from 'react-router-dom';

import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

// Styles
import {
    Error,
    FieldContainer,
    FieldTitle,
    FormContainer,
    SearchResults,
    SearchButtons,
    Select,
    FieldHint,
    LangRadioGroup,
} from './styles';
import { PageContainer } from '../../styles/common';
import { BottomText, CenterContainer, TopText } from '../consent/styles';
import { Textfield } from '../../components/TextfieldQuestion/styles';
import { RadioOption } from '../../components/RadioOption';

// Utils
import { ROUTES, BACKEND_ROUTES } from '../../constants/routes';
import { useStateDebounced } from '../../hooks';
import canadaPost from '../../constants/canadaPost';
import CONSENT from '../../constants/consents';
import Loader from '../../components/Loader';
import { ResetLink } from '../login/styles';

export enum Provinces {
    AB = 'AB',
    BC = 'BC',
    MB = 'MB',
    NB = 'NB',
    NL = 'NL',
    NT = 'NT',
    NS = 'NS',
    NU = 'NU',
    ON = 'ON',
    PE = 'PE',
    QC = 'QC',
    SK = 'SK',
    YT = 'YT',
}

type OptionType = {
    value: string;
    label: string;
    next: string;
};

enum Next {
    Find = 'Find',
    Retrieve = 'Retrieve',
}

const Profile: React.FunctionComponent = () => {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [address1, setAddress1] = useState<string>('');
    const [address2, setAddress2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [province, setProvince] = useState<string>('');
    const [language, setLanguage] = useState<string>('');
    const [phone, setPhone] = useState<string>('');

    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useStateDebounced(search, 400);
    const [locations, setLocations] = useState<OptionType[]>([]);

    const [errorMail, setErrorMail] = useState<boolean>(false);
    const [errorExistingMail, setErrorExistingMail] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const history = useHistory();

    // Scroll to top of page on render
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleAnswer = (field: string, answer: string) => {
        switch (field) {
            case 'firstName':
                setFirstName(answer);
                break;
            case 'lastName':
                setLastName(answer);
                break;
            case 'email':
                setEmail(answer);
                break;
            case 'postalCode':
                setPostalCode(answer);
                break;
            case 'address1':
                setAddress1(answer);
                break;
            case 'address2':
                setAddress2(answer);
                break;
            case 'city':
                setCity(answer);
                break;
            case 'province':
                setProvince(answer);
                break;
            case 'language':
                setLanguage(answer);
                break;
            case 'phone':
                setPhone(answer);
                break;
            default:
        }
    };

    const signup = async () => {
        setIsLoading(true);
        const user = {
            email,
            consentContact: localStorage.getItem(CONSENT.CONTACT) ? 'yes' : 'no',
            consentShare: localStorage.getItem(CONSENT.SHARE) ? 'yes' : 'no',
            consentParticipate: localStorage.getItem(CONSENT.PARTICIPATE) ? 'yes' : 'no',
            profile: {
                firstName,
                lastName,
                postalCode,
                firstAddressLine: address1,
                secondAddressLine: address2,
                city,
                province,
                language,
                phone,
            },
        };

        localStorage.removeItem(CONSENT.CONTACT);
        localStorage.removeItem(CONSENT.SHARE);
        localStorage.removeItem(CONSENT.PARTICIPATE);
        try {
            await axios.post(BACKEND_ROUTES.SIGNUP, user);
            history.push(ROUTES.SIGNUP_CONFIRMATION);
        } catch (err) {
            if (err.response.status === 409) {
                setErrorExistingMail(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const getMask = (field: string) => {
        if (field === 'postalCode') {
            return 'a9a9a9';
        } else if (field === 'phone') {
            return '(999) 999-9999';
        }
        return '';
    };

    const displayField = (field: string, value: string, onblur?: () => void) => {
        return (
            <FieldContainer>
                <FieldTitle>{t(`profile.${field}`)}</FieldTitle>
                <Textfield
                    type={field === 'email' ? 'email' : 'text'}
                    name={field}
                    value={value}
                    onBlur={() => (onblur ? onblur() : null)}
                    mask={getMask(field)}
                    onChange={(event) => handleAnswer(field, event.target.value)}
                    required
                />
                {errorMail && field === 'email' && <Error>{t('profile.validEmail')}</Error>}
            </FieldContainer>
        );
    };

    const checkForm = () => {
        return (
            firstName.length > 0 &&
            firstName.trim() !== '' &&
            lastName.length > 0 &&
            lastName.trim() !== '' &&
            email.length > 0 &&
            !errorMail &&
            postalCode.length > 0 &&
            address1.length > 0 &&
            address1.trim() !== '' &&
            city.length > 0 &&
            city.trim() !== '' &&
            province.length > 0 &&
            language.length > 0 &&
            phone.length > 0
        );
    };

    async function fetchData(debouncedSearch: string, lastId: string) {
        let responseItems = [];
        const response = await axios.get(canadaPost.search(debouncedSearch, lastId));
        if (response?.data?.Items) {
            // Since errors return 200, check if there is an error
            if (!response.data.Items[0].Error) {
                responseItems = response.data.Items.map((item: any) => ({
                    value: item.Id,
                    label: `${item.Text}, ${item.Description}`,
                    next: item.Next,
                }));
            }
        }
        setLocations(responseItems);
    }

    useEffect(() => {
        fetchData(debouncedSearch, '');
    }, [debouncedSearch]);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleLocationSelect = async (location: OptionType) => {
        const id = location.value;
        const next = location.next;
        if (next === Next.Retrieve) {
            const response = await axios.get(canadaPost.getDetails(id));
            if (response?.data?.Items) {
                // Since errors return 200, check if there is an error
                if (!response.data.Items[0].Error) {
                    const selected = response.data.Items[0];
                    setAddress1(`${selected.Line1}`);
                    setPostalCode(selected.PostalCode.replace(/\s/g, ''));
                    setCity(selected.City);
                    setProvince(selected.Province);
                    setSearch('');
                }
            }
        } else if (next === Next.Find) {
            fetchData(debouncedSearch, id);
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <PageContainer>
            <Helmet>
                <title>{t(`profile.profilePage`)}</title>
            </Helmet>
            <Header />
            <CenterContainer>
                <TopText>{t(`profile.title`)}</TopText>
                {errorExistingMail && (
                    <Error>
                        <Trans i18nKey="profile.existingEmail">
                            The email you entered is already being used. If you don't remember your
                            password, please reset it by
                            <ResetLink to={ROUTES.RESET_PASSWORD}>here</ResetLink>.
                        </Trans>
                    </Error>
                )}
                <BottomText>{t(`profile.text`)}</BottomText>
                <FormContainer>
                    {displayField('firstName', firstName)}
                    {displayField('lastName', lastName)}
                    {displayField('email', email, () => setErrorMail(!validator.isEmail(email)))}
                    {displayField('phone', phone)}

                    <FieldContainer>
                        <FieldTitle>{t('profile.searchAddress')}</FieldTitle>
                        <Textfield
                            type={'text'}
                            name={'search'}
                            mask=""
                            value={search}
                            onChange={handleSearch}
                            required
                        />
                        <FieldHint>{t('profile.autofill')}</FieldHint>
                    </FieldContainer>
                    <SearchResults>
                        {locations.map((location) => (
                            <SearchButtons onClick={() => handleLocationSelect(location)}>
                                {location.label}
                            </SearchButtons>
                        ))}
                    </SearchResults>
                    {displayField('address1', address1)}
                    {displayField('address2', address2)}
                    {displayField('postalCode', postalCode)}
                    {displayField('city', city)}
                    <FieldContainer>
                        <FieldTitle>{t('profile.province')}</FieldTitle>
                        <Select
                            value={province}
                            onChange={(event) => handleAnswer('province', event.target.value)}
                        >
                            <option key={''} value={''} />
                            {Object.values(Provinces).map((provinceOption) => (
                                <option key={provinceOption} value={provinceOption}>
                                    {t(`survey.${provinceOption}`)}
                                </option>
                            ))}
                        </Select>
                    </FieldContainer>
                    <FieldContainer>
                        <FieldTitle>{t(`profile.language`)}</FieldTitle>
                        <LangRadioGroup>
                            {['fr', 'en'].map((lang) => (
                                <RadioOption
                                    key={lang}
                                    checked={language === lang}
                                    handleChange={() => handleAnswer('language', lang)}
                                    name={lang}
                                    id={lang}
                                    value={lang}
                                    text={t(`profile.languageOptions.${lang}`)}
                                />
                            ))}
                        </LangRadioGroup>
                    </FieldContainer>
                </FormContainer>
            </CenterContainer>
            <Footer
                onNext={async () => {
                    await signup();
                }}
                hasAnswer={checkForm()}
            />
        </PageContainer>
    );
};

export default Profile;
