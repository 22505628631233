/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { QuestionOptionType, saveObject } from '../../slices/surveySlice';
import {
    DropdownContainer,
    DropdownOuterContainer,
    DropdownTitle,
    Q1Container,
    Select,
} from './styles';

export interface IProps {
    question: number;
    text: string;
    options: QuestionOptionType[];
    value?: number;
}

export const DropdownQuestion: React.FunctionComponent<IProps> = ({ question, options, value }) => {
    const [answer, setAnswer] = useState<string>();
    const [region, setRegion] = useState<string>();
    const dispatch = useDispatch();

    useEffect(() => {
        const isMultiDropdown = options.some((option) => option.question_child.length > 0);

        if (isMultiDropdown) {
            const selectedOption = options.filter((option) =>
                option.question_child.some((child) => child.selected.length > 0),
            );
            if (selectedOption.length > 0) {
                const regionOptions = getSelectedRegionOptions(selectedOption[0].text);
                setRegion(selectedOption[0].text);
                const selectedRegionOption = regionOptions.filter(
                    (option) => option.selected.length > 0,
                );
                setAnswer(selectedRegionOption[0].text);
            }
        } else {
            const selectedOption = options.filter((option) => option.selected.length > 0);
            if (selectedOption.length > 0) {
                setAnswer(selectedOption[0].text);
            }
        }
    }, [options]);

    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedIndex = event.target.options.selectedIndex;
        const dropdownOptions = region ? getSelectedRegionOptions(region) : options;

        setAnswer(dropdownOptions[selectedIndex - 1].text);

        dispatch(
            saveObject({
                question_id: question,
                answer_offered_id: dropdownOptions[selectedIndex - 1].id,
            }),
        );
    };

    const handleRegion = (option: string) => {
        setRegion(option);
    };

    const getSelectedRegionOptions = (region: string) => {
        const selectedRegion = options.find((regionToShow) => regionToShow.text === region);
        if (selectedRegion) {
            return selectedRegion.question_child;
        }
        return [];
    };

    // Checks if question has children prop to trigger multiple dropdowns
    if (options[0].question_child.length > 0) {
        return (
            <Q1Container>
                <DropdownContainer>
                    <DropdownTitle>{t('survey.neighbourhood')}</DropdownTitle>
                    <Select
                        value={region}
                        onChange={(event) => handleRegion(event.currentTarget.value)}
                    >
                        <option key={''} value={''} />
                        {options.map((option) => {
                            return (
                                <option key={option.id} value={option.text}>
                                    {t(`survey.${option.text}`)}
                                </option>
                            );
                        })}
                    </Select>
                </DropdownContainer>

                {region && (
                    <DropdownContainer>
                        <DropdownTitle>{t('survey.school_daycare')}</DropdownTitle>
                        <Select value={answer} onChange={handleChange}>
                            <option key={''} value={''} />
                            {getSelectedRegionOptions(region).map((option) => {
                                return (
                                    <option key={option.id} value={option.text}>
                                        {option.text}
                                    </option>
                                );
                            })}
                        </Select>
                    </DropdownContainer>
                )}
            </Q1Container>
        );
    }

    return (
        <DropdownOuterContainer>
            <DropdownContainer>
                <Select value={answer} onChange={handleChange}>
                    <option key={''} value={''} />
                    {options.map((option) => {
                        return (
                            <option key={option.id} value={option.text}>
                                {t(`survey.${option.text}`)}
                            </option>
                        );
                    })}
                </Select>
            </DropdownContainer>
        </DropdownOuterContainer>
    );
};
