/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { QuestionOptionType, saveObject } from '../../slices/surveySlice';
import { OptionContainer, OptionLabel, OptionsContainer } from '../CheckboxQuestion/styles';
import { CustomRadio, HiddenRadio, Label } from './styles';

import { Question } from '../../constants/questionTypes';

export interface IProps {
    question: number;
    text: string;
    index?: number;
    options: QuestionOptionType[];
}

export const RadioQuestion: React.FC<IProps> = ({ question, text, index, options }) => {
    const [answer, setAnswer] = useState<number>(-1);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const handleChange = (question: number, id: number) => {
        setAnswer(id);
        dispatch(
            saveObject({
                question_id: question,
                answer_offered_id: id,
                order: index,
                type: Question.radio,
            }),
        );
    };

    useEffect(() => {
        let selectedOption = options.filter((option) => option.selected.length > 0);

        if (index) {
            selectedOption = selectedOption.filter(
                (option) => option.selected.findIndex((elm: any) => elm.order === index) !== -1,
            );
        }

        if (selectedOption.length > 0) {
            setAnswer(selectedOption[0].id);
            if (index) {
                dispatch(
                    saveObject({
                        question_id: question,
                        answer_offered_id: selectedOption[0].id,
                        order: index,
                        type: Question.radio,
                    }),
                );
            }
        }
    }, [options, index]);

    return (
        <OptionsContainer>
            {index && <Label>{t(`survey.${text}`)}</Label>}
            {!index && <Label>{t(`survey.selectOne`)}</Label>}
            {options.map((option) => {
                return (
                    <OptionContainer key={option.text}>
                        <CustomRadio
                            checked={answer === option.id}
                            onClick={() => handleChange(question, option.id)}
                        />
                        <HiddenRadio
                            type="radio"
                            name={text}
                            id={option.text}
                            value={option.text}
                            onChange={() => handleChange(question, option.id)}
                        />
                        <OptionLabel htmlFor={option.text}>
                            {t(`survey.${option.text}`)}
                        </OptionLabel>
                    </OptionContainer>
                );
            })}
        </OptionsContainer>
    );
};
