import styled from 'styled-components';

import { colours } from '../constants/colours';
import { sizes } from '../constants/mediaSizes';

export interface IModalSize {
    isBig?: boolean;
}

export const ModalContainer = styled.div`
    display: grid;
    place-items: center;
    position: absolute;
    z-index: 20;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: ${colours.modalGrey};
`;

export const Modal = styled.div<IModalSize>`
    display: grid;
    overflow: auto;
    grid-template-rows: 0 auto;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    border: 2px solid ${colours.mediumGrey};
    box-shadow: 0 4px 4px ${colours.dropShadow};
    padding: 40px;

    @media ${sizes.M} {
        width: ${(props) => (props.isBig ? '80%' : '503px')};
        max-height: 80%;
    }
`;

export const CloseButton = styled.button`
    border: none;
    width: 100%;
    text-align: right;
    transform: translate(30px, -15px);
`;

export const Title = styled.div`
    font-family: 'NunitoBold';
    font-size: 26px;
    color: ${colours.purple};
`;

export const Message = styled.div`
    margin: auto;
    font-family: 'NunitoBold';
    font-size: 20px;
    color: ${colours.black};
`;

export const FieldContainer = styled.div`
    font-family: PoppinsRegular;
    padding: 10px 0;
    font-size: 14px;

    @media ${sizes.XG} {
        font-size: 16px;
    }
`;

export const Label = styled.div`
    color: ${colours.darkGrey};
    padding: 10px 0;
`;

export const Field = styled.input`
    width: 100%;
    height: 48px;
    border-radius: 7px;
    border: 1px solid ${colours.mediumGrey};
    padding: 0 15px;

    &:disabled {
        border: 1px solid rgba(0, 0, 0, 0);
    }
`;

export const ButtonsContainer = styled.div`
    justify-self: end;
    margin-top: 40px;
`;

export const Cancel = styled.button`
    width: 126px;
    height: 44px;
    background-color: ${colours.white};
    color: ${colours.purple};
    border: none;
    border-radius: 10px;
    font-family: NunitoBold;
    transition: all 0.5s ease;
    font-size: 20px;

    &:hover {
        color: ${colours.purpleHover};
        cursor: pointer;
    }
`;

export const Send = styled.button`
    width: 126px;
    height: 44px;
    background-color: ${colours.purple};
    color: ${colours.white};
    border-radius: 10px;
    border: none;
    font-family: NunitoBold;
    font-size: 20px;
    transition: all 0.5s ease;

    &:hover {
        background-color: ${colours.purpleHover};
        cursor: pointer;
    }
`;
