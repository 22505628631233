import styled from 'styled-components';

import { colours } from '../../constants/colours';

import { sizes } from '../../constants/mediaSizes';

interface IColumn {
    minWidth?: string;
}

export const Table = styled.table`
    width: 60%;

    @media ${sizes.XP} {
        display: none;
    }

    @media ${sizes.M} {
        display: revert;
    }
`;

export const Column = styled.th<IColumn>`
    min-width: ${(props) => (props.minWidth ? props.minWidth : '130px')};
    font-family: PoppinsRegular;
    font-weight: 400;
    color: ${colours.darkGrey};
`;

export const Row = styled.tr`
    margin-top: 10px;
    height: 45px;
`;

export const BorderedRow = styled.tr`
    height: 45px;
    border-top: solid 1px ${colours.grey};
`;

export const QuestionContainer = styled.td`
    text-align: left;
`;

export const Question = styled.li`
    font-family: PoppinsMedium;
    text-align: left;

    @media ${sizes.XP} {
        margin-top: 35px;
        font-size: 20px;
    }

    @media ${sizes.G} {
        min-width: 300px;
        font-size: 16px;
        margin: 0;
    }
`;

export const OptionContainer = styled.div`
    @media ${sizes.XP} {
        display: flex;
        align-items: center;
        margin: 25px 0;
    }

    @media ${sizes.M} {
        display: grid;
        place-items: center;
        margin-bottom: 4px;
    }
`;

export const OptionLabel = styled.label`
    font-family: PoppinsRegular;
    margin-left: 18px;
`;

export const Mobile = styled.div`
    @media ${sizes.M} {
        display: none;
    }
`;
