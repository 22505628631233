import { configureStore, Action, combineReducers } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import surveyReducer from './slices/surveySlice';
import authReducer from './slices/authSlice';
import adminReducer from './slices/adminSlice';
import createUserReducer from './slices/createUserSlice';
import updateParticipantInfoReducer from './slices/updateParticipantInfoSlice';
import getParticipantInfoReducer from './slices/getParticipantInfoSlice';

const rootReducer = combineReducers({
    survey: surveyReducer,
    auth: authReducer,
    admin: adminReducer,
    createUser: createUserReducer,
    getParticipantInfo: getParticipantInfoReducer,
    updateParticipantInfo: updateParticipantInfoReducer,
});

const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
